import React, { forwardRef, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Input } from '../../atoms/Input';
import { FieldError } from '../../atoms/FieldError';
import { get, debounce } from 'lodash';
import { FormReactSelect } from '../FormReactSelect';
import { TextArea } from 'components/atoms/TextArea';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';
import { WAButtons } from '../FormFieldArray/WAButtons';
import GenerateImagePathModal from '../WaToggleInput/GenerateImagePathModal';
import GenerateImageInput from '../WaToggleInput/GenerateImageInput';
import GenerateVideoInput from '../WaToggleInput/GenerateVideoInput';
import WARichTextInputWrapper from '../WARichTextInputWrapper';
import { EnhancedTextArea } from 'components/molecules/EnhancedTextArea';
import Alert from 'components/Alert';
import { set } from 'lodash';
import { moveInArray, removeByIndex } from 'utilities/util';
const MAX_CAROUSAL_CARDS = 10;
const MAX_CAROUSAL_BUTTONS = 2;
const options = [
  { label: 'Single message card', value: 'single' },
  { label: 'Carousal message - multiple cards', value: 'carousel' }
];

const WaToggleHeaderTextField = ({
  control,
  watchInput,
  errors,
  name,
  register,
  dateFormat,
  defaultValue,
  setValue,
  abTesting,
  defaultWatchValue,
  disabled = { disabled }
}) => {
  let callBackFn = null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setTabImageUrl] = useState();
  const triggerType = useWatch({
    control,
    name: watchInput,
    defaultValue: defaultWatchValue
  });

  const handleSubmitImagePath = (url, frm) => {
    setIsModalOpen(false);
    setTabImageUrl(url);
  };

  return triggerType === 'headerImage' ? (
    <>
      <Controller
        name={`${name}.headerImage`}
        control={control}
        defaultValue={get(defaultValue, `headerImage`)}
        render={({ field }) => (
          <GenerateImageInput
            {...field}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            fieldPrefix={`${name}`}
            disabled={disabled}
          />
        )}
      />
    </>
  ) : triggerType === 'headerVideo' ? (
    <>
      <Controller
        name={`${name}.headerVideo`}
        control={control}
        defaultValue={get(defaultValue, `headerVideo`)}
        render={({ field }) => (
          <GenerateVideoInput
            {...field}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            fieldPrefix={`${name}`}
            disabled={disabled}
          />
        )}
      />
    </>
  ) : triggerType === 'headerDocument' ? (
    <>
      <p>headerDocument</p>
    </>
  ) : null;
};

const WaToggleRadioInput = forwardRef(
  ({ name, value, onChange, canRemove, removeCard, disabled, index }, ref) => {
    const handleOnChange = e => {
      onChange(e.target.value);
    };
    return (
      <div className="themes-container d-flex flex-wrap">
        <label
          htmlFor={`headerImage-${index}`}
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerImage',
              disabled: disabled
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id={`headerImage-${index}`}
              value="headerImage"
              type="radio"
              checked={value === 'headerImage'}
              onChange={handleOnChange}
              disableStyleClass={true}
              disabled={disabled}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="image outline" size="large" />
              <p className="theme-label text-size-small">{'Image'}</p>
            </div>
          </div>
        </label>

        <label
          htmlFor={`headerVideo-${index}`}
          className={classNames(
            'theme-item theme-item-wide d-flex align-items-center',
            {
              selected: value === 'headerVideo',
              disabled: disabled
            }
          )}
        >
          <div className="mb-0">
            <Input
              name={`${name}`}
              id={`headerVideo-${index}`}
              value="headerVideo"
              type="radio"
              checked={value === 'headerVideo'}
              onChange={handleOnChange}
              disableStyleClass={true}
              disabled={disabled}
            />
            <div className="label-text d-flex justify-content-center align-items-center">
              <Icon name="file video outline" size="large" />
              <p className="theme-label text-size-small">{'Video'}</p>
            </div>
          </div>
        </label>
      </div>
    );
  }
);

const CarouselCard = ({
  name,
  key,
  item,
  index,
  control,
  errors,
  register,
  setValue,
  dateFormat,
  defaultValue,
  canRemove,
  removeCard,
  syncHeaderType,
  syncButtonType,
  syncButtonName,
  syncAddButton,
  syncButtonSorting,
  syncButtonRemove,
  disabled
}) => {
  return (
    <div className="nt-10 mb-10 wa-design-sections" key={key}>
      <div className="d-flex mb-10 position-relative">
        <div>
          <label>
            <strong>{`Carousal card ${index + 1}`}</strong>
          </label>
        </div>
        {canRemove && (
          <div>
            <div className="wa-carousel-cross-btn">
              <button
                type="button"
                className="btn-circle btn-remove ml-10"
                onClick={() => removeCard(index)}
                disabled={disabled}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        )}
      </div>

      <Alert
        alertType={'warningMessageWithIcon'}
        textMessage={
          'All carousal cards will have the same structure as the 1st carousal'
        }
        showComponent={index === 0}
        wrapperClass="carousal-alert-text"
      />

      {/* Card Header */}
      <div className="form-group">
        <label htmlFor={`Card Header ${index + 1}`}>{`Card Header`}</label>
        <Controller
          name={`${name}.carouselCards.${index}.cardHeader.type`}
          control={control}
          // defaultValue={get(defaultValue, `${name}.type`)}
          defaultValue={get(item, 'cardHeader.type', 'headerImage')}
          render={({ field }) => (
            <WaToggleRadioInput
              {...field}
              disabled={disabled || index !== 0}
              index={index}
              onChange={val => {
                field.onChange(val);
                console.log('val: ', val);
                if (index === 0) {
                  syncHeaderType(val);
                }
              }}
            />
          )}
        />
        <WaToggleHeaderTextField
          control={control}
          watchInput={`${name}.carouselCards.${index}.cardHeader.type`}
          defaultWatchValue={get(item, `cardHeader.type`, 'headerImage')}
          errors={errors}
          register={register}
          name={`${name}.carouselCards.${index}.cardHeader`}
          dateFormat={dateFormat}
          defaultValue={get(item, `cardHeader`, { type: 'headerImage' })}
          setValue={setValue}
          abTesting={false}
          disabled={disabled}
        />
      </div>

      {/* Card Body Text */}
      <EnhancedTextArea
        name={`${name}.carouselCards.${index}.cardBodyText`}
        id={`cardBodyText-${index}`}
        defaultValue={get(item, `cardBodyText`, '')}
        control={control}
        label={`Card body text`}
        errors={errors}
        maxLength={160}
        maxLengthDisplay={160}
        setValue={setValue}
        flow="wa"
        isDynamicCoupon={false}
        optOutTextLength={0}
        insertingURLMain={false}
        hideInsertUrl={true}
        fnameOnlyOnce={true}
        showEmojiButton={true}
        showAddVariables={true}
        showInput={true}
        placeholder={
          'Please enter WhatsApp card body content and use @ to add dynamic fields'
        }
        register={register}
        disabled={disabled}
        defaultValueDynamicVar={
          defaultValue?.carousel?.carouselCards[index]
            ?.cardBodyTextDynamicVariables
        }
      />

      <div className="form-group">
        <Controller
          name={`${name}.carouselCards.${index}.buttons`}
          control={control}
          render={({ field }) => (
            <WAButtons
              {...field}
              name={`${name}.carouselCards.${index}.buttons`}
              type={'custom'}
              label="Buttons"
              subLabel="Atleast one button is mandatory. You can add upto 10 buttons."
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              showCommonErrors={false}
              defaultValue={get(item, `buttons`, [
                {
                  type: {
                    value: '',
                    label: ''
                  },
                  buttonName: '',
                  buttonValue: ''
                }
              ])}
              maxRow={MAX_CAROUSAL_BUTTONS}
              isMulti={true}
              disabled={disabled || index !== 0}
              cardIndex={index}
              syncButtonType={syncButtonType}
              syncButtonName={syncButtonName}
              syncAddButton={syncAddButton}
              syncButtonSorting={syncButtonSorting}
              syncButtonRemove={syncButtonRemove}
              isEditDisabled={disabled}
            />
          )}
        />
      </div>
      {/* <FieldError
        errors={errors}
        name={`${name}.carouselCards.${index}.buttons`}
      /> */}
    </div>
  );
};

export const WACarouselMessageInput = ({
  name,
  rules,
  register,
  label,
  subLabel,
  errors,
  setValue,
  control,
  defaultValue,
  defaultWatchValue,
  waType,
  abTesting,
  dateFormat,
  defaultValueDynamicVar,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  const messageType = useWatch({
    control,
    name: `${name}.messageType`,
    defaultValue: get(defaultValue, `${name}.messageType`, {
      label: 'Single message card',
      value: 'single'
    }) // Set 'Single message card' as the default selected value
  });

  const templateUse = useWatch({
    control,
    name: `templateUse`,
    defaultValue: get(defaultValue, `templateUse`)
  });
  const isInputDisabled = templateUse === 'reuse';

  const carouselCardsDataWatch = useWatch({
    control,
    name: `${name}.carouselCards`,
    defaultValue: []
  });

  const carouselCards = get(defaultValue, `${name}.carouselCards`);
  let [carouselFields, setCarouselFields] = useState([]);
  useEffect(() => {
    if (messageType && messageType.value === 'single') {
      setCarouselFields([]);
    } else if (messageType && messageType.value === 'carousel') {
      setCarouselFields(
        carouselCards && carouselCards.length
          ? carouselCards
          : [
              {
                cardHeader: { type: 'headerImage' },
                cardBodyText: '',
                buttons: [
                  {
                    type: {
                      value: '',
                      label: ''
                    },
                    buttonName: '',
                    buttonValue: ''
                  }
                ]
              },
              {
                cardHeader: { type: 'headerImage' },
                cardBodyText: '',
                buttons: [
                  {
                    type: {
                      value: '',
                      label: ''
                    },
                    buttonName: '',
                    buttonValue: ''
                  }
                ]
              }
            ]
      );
    }
  }, [messageType]);

  const addCard = () => {
    if (carouselFields.length < 10) {
      let newFieldData = [...carouselCardsDataWatch];
      const firstCard = newFieldData[0];
      const newCard = {
        cardHeader: { type: firstCard?.cardHeader?.type },
        cardBodyText: '',
        buttons: firstCard.buttons.map(item => ({ ...item, buttonValue: '' }))
      };
      newFieldData.push(newCard);
      setCarouselFields(newFieldData);
    }
  };

  const remove = index => {
    let updatedFields = [...carouselCardsDataWatch];
    updatedFields.splice(index, 1);
    setCarouselFields(updatedFields);
    setValue(`${name}.carouselCards`, updatedFields);
  };

  const syncHeaderType = val => {
    const updatedFields = [...carouselCardsDataWatch];
    // update all the remaining card
    for (let i = 0; i < updatedFields.length; i++) {
      set(updatedFields, `${i}.cardHeader.type`, val);
    }
    setCarouselFields(updatedFields);
    setValue(`${name}.carouselCards`, updatedFields);
  };

  const syncButtonType = (val, btnIndex, defaultVal) => {
    const updatedFields = [...carouselCardsDataWatch];
    // // update all the remaining card
    for (let i = 1; i < updatedFields.length; i++) {
      setValue(`${name}.carouselCards.${i}.buttons.${btnIndex}.type`, val, {
        shouldValidate: true
      });
      setValue(
        `${name}.carouselCards.${i}.buttons.${btnIndex}.buttonValue`,
        defaultVal
      );
    }
  };

  const syncButtonName = (val, btnIndex) => {
    const updatedFields = [...carouselCardsDataWatch];
    // // update all the remaining card
    for (let i = 1; i < updatedFields.length; i++) {
      const some = setValue(
        `${name}.carouselCards.${i}.buttons.${btnIndex}.buttonName`,
        val,
        { shouldValidate: true }
      );
    }
  };

  const syncAddButton = rowD => {
    const updatedFields = [...carouselCardsDataWatch];
    // update all the remaining card
    for (let i = 1; i < updatedFields.length; i++) {
      setValue(`${name}.carouselCards.${i}.buttons`, [
        ...updatedFields[i].buttons,
        rowD
      ]);
    }
  };

  const syncButtonSorting = (sourceIndex, destIndex) => {
    const updatedFields = [...carouselCardsDataWatch];
    // // update all the remaining card
    for (let i = 1; i < updatedFields.length; i++) {
      const newList = moveInArray(
        updatedFields[i].buttons,
        sourceIndex,
        destIndex
      );
      if (newList) {
        setValue(`${name}.carouselCards.${i}.buttons`, newList);
      }
    }
  };

  const syncButtonRemove = pos => {
    const updatedFields = [...carouselCardsDataWatch];
    // // update all the remaining card
    for (let i = 1; i < updatedFields.length; i++) {
      const newList = removeByIndex(updatedFields[i].buttons, pos);
      if (newList) {
        setValue(`${name}.carouselCards.${i}.buttons`, newList);
      }
    }
  };

  return (
    <>
      <div
        className="form-group email-secondary-theme wa-design-section-bottom"
        aria-live="polite"
      >
        <Controller
          control={control}
          name={`${name}.messageType`}
          defaultValue={get(defaultValue, `${name}.messageType`, {
            label: 'Single message card',
            value: 'single'
          })}
          render={({ field }) => (
            <FormReactSelect
              name={`${name}.messageType`}
              id="messageType"
              label={label}
              placeholder="Choose a message type"
              control={control}
              errors={errors}
              options={options}
              setValue={setValue}
              extraOptions={[]}
              showExtraOptions={false}
              hasNestedError={true}
              {...field}
              defaultDisabled={isInputDisabled}
              normalDisabledMode={true}
            />
          )}
        />
      </div>
      <div className="email-secondary-theme">
        {/* Carousel Message Configuration */}
        {messageType && messageType.value === 'carousel' && (
          <div>
            <label htmlFor={props.id}>
              <strong>{`Configure your carousal message`}</strong>
            </label>
            {/* Main Message Bubble Text */}
            <WARichTextInputWrapper
              name={`${name}.mainMessageBubbleText`}
              id="mainMessageBubbleText"
              label="Main message bubble text"
              control={control}
              defaultValue={get(
                defaultValue,
                `${name}.mainMessageBubbleText`,
                ''
              )}
              errors={errors}
              formGroupWrapperClass="mt-1 wa-design-sections"
              register={register}
              setValue={setValue}
              watch={props.watch}
              defaultValueDynamicVar={
                defaultValue?.carousel?.mainMessageBubbleTextDynamicVariables
              }
              maxLength={1024}
              showCharCount={true}
              disabled={isInputDisabled}
            />
            {carouselFields.map((item, index) => (
              <Controller
                name={`carouselCards.${index}`}
                control={control}
                render={({ field }) => (
                  <CarouselCard
                    name={name}
                    key={item.id}
                    item={item}
                    index={index}
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    dateFormat={dateFormat}
                    removeCard={remove}
                    canRemove={carouselFields.length > 2}
                    syncHeaderType={syncHeaderType}
                    defaultValue={defaultValue}
                    syncButtonType={syncButtonType}
                    syncButtonName={syncButtonName}
                    syncAddButton={syncAddButton}
                    syncButtonSorting={syncButtonSorting}
                    syncButtonRemove={syncButtonRemove}
                    disabled={isInputDisabled}
                  />
                )}
              />
            ))}
            {carouselFields.length < MAX_CAROUSAL_CARDS && (
              <button
                type="button"
                className="btn btn-primary mt-20"
                onClick={addCard}
                disabled={isInputDisabled}
              >
                Add carousal card
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
