// Dashboard
export const GET_CAMPAIGNS_LIST = 'GET_CAMPAIGNS_LIST';
export const FETCHING_CAMPAIGNS = 'FETCHING_CAMPAIGNS';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const UPDATE_CAMPAIGN_TYPE = 'UPDATE_CAMPAIGN_TYPE';
export const SERVICE_ERROR_DASHBOARD = 'SERVICE_ERROR_DASHBOARD';
export const SET_DASHBOARD_NOTIFICATION_MESSAGE =
  'SET_DASHBOARD_NOTIFICATION_MESSAGE';
export const GET_DASHBOARD_BRAND_DETAILS = 'GET_DASHBOARD_BRAND_DETAILS';
export const DASHBOARD_BRAND_DETAIL_SUCCESS = 'DASHBOARD_BRAND_DETAIL_SUCCESS';
export const GET_DASHBOARD_BRAND_COUNTRIES = 'GET_DASHBOARD_BRAND_COUNTRIES';
export const DASHBOARD_BRAND_COUNTRY_LOADED_SUCCESS =
  'DASHBOARD_BRAND_COUNTRY_LOADED_SUCCESS';
export const SET_ADVANCE_SEARCH = 'SET_ADVANCE_SEARCH';

export const ASYNC_IN_PROGRESS = 'ASYNC_IN_PROGRESS';
export const FETCHING_DASHBOARD_BRANDS = 'FETCHING_DASHBOARD_BRANDS';
export const GET_DASHBOARD_BRAND_LIST = 'GET_DASHBOARD_BRAND_LIST';
export const GET_DASHBOARD_LANGUAGE_LIST = 'GET_DASHBOARD_LANGUAGE_LIST';
export const RESET_APP_PROPS = 'RESET_APP_PROPS';
export const SET_EDITED_CAMPAIGNS_DATA = 'SET_EDITED_CAMPAIGNS_DATA';

// Campaign Overview
export const SERVICE_ERROR_OVERVIEW = 'SERVICE_ERROR_OVERVIEW';
export const GET_CAMPAIGN_DETAILS_OVERVIEW = 'GET_CAMPAIGN_DETAILS_OVERVIEW';
export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const UPDATE_ADDITIONAL_OPTINS = 'UPDATE_ADDITIONAL_OPTINS';
export const ACTIVE_STEP = 'ACTIVE_STEP';
export const SET_CAMPAIGN_RECORD_ID = 'SET_CAMPAIGN_RECORD_ID';
export const SET_DELETED_CAMPAIGN = 'SET_DELETED_CAMPAIGN';
export const SET_CAMPAIGN_TO_EDITED = 'SET_CAMPAIGN_TO_EDITED';
export const RESET_CAMPAIGN_TO_EDITED = 'RESET_CAMPAIGN_TO_EDITED';

// Brand Details
export const GET_BRAND_COUNTRIES = 'GET_BRAND_COUNTRIES';
export const GET_CAMPAIGN_DETAILS_BRANDDETAILS =
  'GET_CAMPAIGN_DETAILS_BRANDDETAILS';
export const BRAND_COUNTRY_LOADED_SUCCESS = 'BRAND_COUNTRY_LOADED_SUCCESS';
export const BRAND_COUNTRY_LOADED = 'BRAND_COUNTRY_LOADED';
export const GET_COUNTRY_BRAND_LIST = 'GET_COUNTRY_BRAND_LIST';
export const GET_BRAND_DETAILS = 'GET_BRAND_DETAILS';
export const BRAND_DETAILS_NOT_FOUND = 'BRAND_DETAILS_NOT_FOUND';
export const PROFILE_STORE_FOUND = 'PROFILE_STORE_FOUND';
export const SET_PROFILE_STORE_TYPE = 'SET_PROFILE_STORE_TYPE';
export const RESET_PROFILE_STORE = 'RESET_PROFILE_STORE';
export const UPDATE_BRAND_DETAILS = 'UPDATE_BRAND_DETAILS';
export const BRAND_DETAIL_SUCCESS = 'BRAND_DETAIL_SUCCESS';
export const SET_PROFILE_STORE_ERROR = 'SET_PROFILE_STORE_ERROR';
export const PROFILE_STORE_IN_PROGRESS = 'PROFILE_STORE_IN_PROGRESS';
export const RESET_PROFILE_STORE_IN_PROGRESS =
  'RESET_PROFILE_STORE_IN_PROGRESS;';
export const RESET_BRAND_DETAILS = 'RESET_BRAND_DETAILS';

//campaign type
export const UDPATE_CAMPAIGN_TYPE_DETAILS = 'UDPATE_CAMPAIGN_TYPE_DETAILS';

// Questions
export const UPDATE_QUESTION_DATA = 'UPDATE_QUESTION_DATA';
export const ADD_QUESTION_TYPE = 'ADD_QUESTION_TYPE';
export const ADD_QUESTION = 'ADD_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const ADD_ANSWER = 'ADD_ANSWER';
export const DELETE_ANSWER = 'DELETE_ANSWER';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const SAVE_QUESTIONSANSWERS_DATA = 'SAVE_QUESTIONSANSWERS_DATA';

// Campaign Approval
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const GET_MODAL_STATUS = 'GET_MODAL_STATUS';
export const APPROVE_CAMPAIGN = 'APPROVE_CAMPAIGN';
export const REJECT_CAMPAIGN = 'REJECT_CAMPAIGN';
export const REDIRECT = 'REDIRECT';
export const SET_APPROVAL_ERROR = 'SET_APPROVAL_ERROR';
export const APPROVAL_EDIT_CAMPAIGN = 'APPROVAL_EDIT_CAMPAIGN';
export const SET_APPROVAL_CAMPAIGN_UPDATION_SUCCESS =
  'SET_APPROVAL_CAMPAIGN_UPDATION_SUCCESS';
export const FETCH_CAMPIGNEDITLOGS_IN_PROGRESS =
  'FETCH_CAMPIGNEDITLOGS_IN_PROGRESS';
export const GET_CAMPAIGN_EDITLOGS = 'GET_CAMPAIGN_EDITLOGS';

//Masterdata
export const SET_MASTERDATA = 'SET_MASTERDATA';
export const UDPATE_CAMPAIGN_MASTERDATA = 'UDPATE_CAMPAIGN_MASTERDATA';
export const UPDATE_MD_QUESTIONS = 'UPDATE_MD_QUESTIONS';
export const UPDATE_MD_ANSWERS = 'UPDATE_MD_ANSWERS';
export const SAVE_DRAFT = 'SAVE_DRAFT';
export const UPDATE_UNILEVER_OPTION_MASTER_DATA =
  'UPDATE_UNILEVER_OPTION_MASTER_DATA';
export const UPDATE_BRAND_OPTION_MASTER_DATA =
  'UPDATE_BRAND_OPTION_MASTER_DATA';
export const UPDATE_UNILEVER_ID_MASTER_DATA = 'UPDATE_UNILEVER_ID_MASTER_DATA';
export const RESET_CAMPAIGN_ID = 'RESET_CAMPAIGN_ID';
export const SET_CAMPAIGNID = 'SET_CAMPAIGNID';

// Campaign Details
export const UDPATE_CAMPAIGN_DETAILS = 'UDPATE_CAMPAIGN_DETAILS';
export const PUSH_STATE = 'PUSH_STATE';
export const SERVICE_ERROR_CAMPAIGN_DETAILS = 'SERVICE_ERROR_CAMPAIGN_DETAILS';
export const SERVICE_ERROR_CAMPAIGN_SUBMISSION =
  'SERVICE_ERROR_CAMPAIGN_SUBMISSION';
export const RESET_FORM_SUBMISSION = 'RESET_FORM_SUBMISSION';
export const SAVE_CAMPAIGN_DATA = 'SAVE_CAMPAIGN_DATA';
export const CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN';
export const GET_CAMPAIGN_COOKIE_PRIVACY_URL =
  'GET_CAMPAIGN_COOKIE_PRIVACY_URL';
//CLONE CAMPAIGN ACTION
export const CLONE_CAMPAIGN_DATA = 'CLONE_CAMPAIGN_DATA';
export const PUSH_CLONED_DATA = 'PUSH_CLONED_DATA';
//PRM
export const UPDATE_PROFILE_STORE = 'UPDATE_PROFILE_STORE';
export const PRM_DATA_SUBMITTED = 'PRM_DATA_SUBMITTED;';
export const PRM_DRAFT_SUBMITTED = 'PRM_DRAFT_SUBMITTED';

// Review
export const GET_REVIEW_CAMPAIGN = 'GET_REVIEW_CAMPAIGN';
export const POST_SUBMIT_REVIEW = 'POST_SUBMIT_REVIEW';
export const SERVICE_ERROR_REVIEW = 'SERVICE_ERROR_REVIEW';
export const UDPATE_CAMPAIGN_DETAILS_REVIEW = 'UDPATE_CAMPAIGN_DETAILS_REVIEW';

// Questions
export const QUESTIONS_FETCH_SUCCESS = 'QUESTIONS_FETCH_SUCCESS';
export const ANSWERS_FETCH_SUCCESS = 'ANSWERS_FETCH_SUCCESS';
export const CAMPAIGN_DETAILS_FETCH_SUCCESS = 'CAMPAIGN_DETAILS_FETCH_SUCCESS';
export const FETCH_ANSWERS = 'FETCH_ANSWERS';
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_CAMPAIGN_DETAILS = 'FETCH_CAMPAIGN_DETAILS';
export const SUBMITTING_QUESTIONSANSWERS = 'SUBMITTING_QUESTIONSANSWERS';
export const SUBMIT_QUESTIONSANSWERS_SUCCESS =
  'SUBMIT_QUESTIONSANSWERS_SUCCESS';
export const RESET_QNA_SUBMISSION = 'RESET_QNA_SUBMISSION';

// GIGYA
export const GIGYA_DETAILS_SUBMITTED = 'GIGYA_DETAILS_SUBMITTED';
export const UPDATE_GIGYA_DETAILS = 'UPDATE_GIGYA_DETAILS';
export const GIGYA_DRAFT_SUBMITTED = 'GIGYA_DRAFT_SUBMITTED';

//user management
export const GET_USER_DATA = 'GET_USER_DATA';
export const NEW_USER_DATA_ADDED = 'NEW_USER_DATA_ADDED';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const DELETE_USER = 'DELETE_USER';
export const USER_CANNOT_DELETED = 'USER_CANNOT_DELETED';
export const ASYNC_IN_PROGRESS_USER_MANAGEMENT =
  'ASYNC_IN_PROGRESS_USER_MANAGEMENT';
export const USER_EMAIL_ALREADY_EXISTS = 'USER_EMAIL_ALREADY_EXISTS';
export const UPDATE_APPROVER_DETAILS = 'UPDATE_APPROVER_DETAILS';
export const GET_APPROVER_COUNTRIES = 'GET_APPROVER_COUNTRIES';
export const APPROVER_COUNTRY_LOADED_SUCCESS =
  'APPROVER_COUNTRY_LOADED_SUCCESS';

//authorization
export const GET_AUTHORIZED_USER_DATA = 'GET_AUTHORIZED_USER_DATA';
export const ASYNC_IN_PROGRESS_AUTHORIZATION =
  'ASYNC_IN_PROGRESS_AUTHORIZATION';
export const USER_AUTHORIZED = 'USER_AUTHORIZED';
export const USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED';
export const TAB_CHANGE_USER = 'TAB_CHANGE_USER';

//promotigo modal

export const PROMOTIGO_SHOW_MODAL = 'PROMOTIGO_SHOW_MODAL';
export const PROMOTIGO_CLOSE_MODAL = 'PROMOTIGO_CLOSE_MODAL';
export const UDPATE_PROMOTIGO_CALCULATOR_DETAILS =
  'UDPATE_PROMOTIGO_CALCULATOR_DETAILS';
export const CLEAN_PROMOTIGO_MODAL_DATA = 'CLEAN_PROMOTIGO_MODAL_DATA';
export const ASYNC_IN_PROGRESS_PROMOTIONS = 'ASYNC_IN_PROGRESS_PROMOTIONS';
export const SET_PROMOTIGO_CALCULATED_COST = 'SET_PROMOTIGO_CALCULATED_COST';
export const PROMOTIGO_API_ERROR = 'PROMOTIGO_API_ERROR';

//question answer sorting
export const QASORT_GET_CAMPAIGN = 'QASORT_GET_CAMPAIGN';
export const QASORT_ASYNC_IN_PROGRESS = 'QASORT_ASYNC_IN_PROGRESS';
export const UDPATE_QASORT_DETAILS = 'UDPATE_QASORT_DETAILS';
export const SUBMITTING_QASORT = 'SUBMITTING_QASORT';
export const QASORT_SUCCESS = 'QASORT_SUCCESS';
export const RESET_QNA_SORT_SUBMISSION = 'RESET_QNA_SORT_SUBMISSION';

// campaign history logs
export const GET_CAMPAIGN_HISTORY_LOGS = 'GET_CAMPAIGN_HISTORY_LOGS';
export const FETCH_REJECTED_CAMPAIGN_HISTORY_LOGS =
  'FETCH_REJECTED_CAMPAIGN_HISTORY_LOGS';

// requester tab under user management
export const ASYNC_IN_PROGRESS_REQUESTER_TAB =
  'ASYNC_IN_PROGRESS_REQUESTER_TAB';
export const DOMAIN_ALREADY_EXISTS = 'DOMAIN_ALREADY_EXISTS';
export const GET_DOMAIN_DATA = 'GET_DOMAIN_DATA';
export const NEW_DOMAIN_ADDED = 'NEW_DOMAIN_ADDED';
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const DOMAIN_CANNOT_BE_DELETED = 'DOMAIN_CANNOT_BE_DELETED';

// campaign authoring access
export const FETCH_EXISTING_EMAIL_LIST = 'FETCH_EXISTING_EMAIL_LIST';
export const GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID =
  'GET_AUTHOR_ACCESS_EMAILS_BY_CAMPAIGN_ID';
export const AUTHORING_PUSH_STATE = 'AUTHORING_PUSH_STATE';
export const AUTHORING_DATA_SAVED = 'AUTHORING_DATA_SAVED';
export const RESET_AUTHORING_FORM_SUBMISSION =
  'RESET_AUTHORING_FORM_SUBMISSION';
export const AUTHORING_INPROGRESS = 'AUTHORING_INPROGRESS';
export const AUTHORING_CAMPAIGN_DETAILS = 'AUTHORING_CAMPAIGN_DETAILS';

//Autopopulate campaign website type
export const AUTOPOPULATE_WEBSITETYPE = 'AUTOPOPULATE_WEBSITETYPE';

//SMS Communications module

export const UPDATE_SMS_BRAND_DETAILS = 'UPDATE_SMS_BRAND_DETAILS';
export const FETCH_SMS_BRAND_DETAILS = 'FETCH_SMS_BRAND_DETAILS';
export const FETCH_ALL_SMS_BRANDS = 'FETCH_ALL_SMS_BRANDS';
export const FETCH_SMS_BRANDS_FOR_COUNTRY = 'FETCH_SMS_BRANDS_FOR_COUNTRY';
export const FETCH_SMS_CAMPAIGN_DETAILS = 'FETCH_SMS_CAMPAIGN_DETAILS';
export const SUBMIT_SMS_BRAND_DETAILS_INFO = 'SUBMIT_SMS_BRAND_DETAILS_INFO';
export const GET_SMS_CAMPAIGN_DETAILS = 'GET_SMS_CAMPAIGN_DETAILS';
export const UDPATE_SMS_CAMPAIGN_DETAILS = 'UDPATE_SMS_CAMPAIGN_DETAILS';
export const GET_FETCH_SMS_CAMPAIGN_DETAILS = 'GET_FETCH_SMS_CAMPAIGN_DETAILS';
export const FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY =
  'FETCH_SMS_CAMPAIGN_DETAILS_FOR_COUNTRY';
export const SUBMIT_SMS_CAMPAIGN_DETAILS = 'SUBMIT_SMS_CAMPAIGN_DETAILS';
export const GET_SMS_REVIEW_DETAIL = 'GET_SMS_REVIEW_DETAIL';
export const SMS_ASYNC_IN_PROGRESS = 'SMS_ASYNC_IN_PROGRESS';
export const GET_SMS_OVERVIEW_DETAIL = 'GET_SMS_OVERVIEW_DETAIL';
export const SMS_SYSTEM_ERROR = 'SMS_SYSTEM_ERROR';
export const SMS_SERVICE_ERROR = 'SMS_SERVICE_ERROR';
export const GET_SMS_CAMPAIGNS_LIST = 'GET_SMS_CAMPAIGNS_LIST';
export const FETCHING_SMS_CAMPAIGNS = 'FETCHING_SMS_CAMPAIGNS';
export const VALIDATE_SMS_CAMPAIGN_NAME = 'VALIDATE_SMS_CAMPAIGN_NAME';
export const RESET_SMS_BRAND_DETAILS = 'RESET_SMS_BRAND_DETAILS';
export const RESET_SMS_CAMPAIGN_DETAILS = 'RESET_SMS_CAMPAIGN_DETAILS';
export const SUBMIT_SMS_CAMPAIGN_TYPE = 'SUBMIT_SMS_CAMPAIGN_TYPE';

//Saas Form Field Authoring
export const GET_SAAS_FORM_FIELD_METADATA = 'GET_SAAS_FORM_FIELD_METADATA';
export const SAAS_ADD_FORM_META_DATA = 'SAAS_ADD_FORM_META_DATA';
export const SAAS_REMOVE_FORM_META_DATA = 'SAAS_REMOVE_FORM_META_DATA';
export const GET_PREVIEW_URL = 'GET_PREVIEW_URL';
export const GET_PREVIEW_ERROR_PAGE = 'GET_PREVIEW_ERROR_PAGE';
export const PREVIEW_ASYNC_IN_PROGRESS = 'PREVIEW_ASYNC_IN_PROGRESS';
export const GET_SAAS_FORM_GENERIC_METADATA = 'GET_SAAS_FORM_GENERIC_METADATA';
export const GET_SAAS_FORM_ELEMENTS_METADATA =
  'GET_SAAS_FORM_ELEMENTS_METADATA';

export const FIELD_SORT_ASYNC_IN_PROGRESS = 'FIELD_SORT_ASYNC_IN_PROGRESS';
export const FIELD_SORT_GET_CAMPAIGN = 'FIELD_SORT_GET_CAMPAIGN';
export const UDPATE_PROFILE_FIELDS = 'UDPATE_PROFILE_FIELDS';

export const GET_CAMPAIGN_DETAILS_CONSENTS = 'GET_CAMPAIGN_DETAILS_CONSENTS';
export const GET_ALL_CONSENTS_FOR_LOCALE = 'GET_ALL_CONSENTS_FOR_LOCALE';
export const REMOVE_ALL_CONSENTS_FOR_LOCALE = 'REMOVE_ALL_CONSENTS_FOR_LOCALE';
export const CONSENTS_ASYNC_IN_PROGRESS = 'CONSENTS_ASYNC_IN_PROGRESS';
export const GET_CONSENTS_FOR_SELECTED_VERSION =
  'GET_CONSENTS_FOR_SELECTED_VERSION';
export const GET_SAAS_CONSENT_METADATA = 'GET_SAAS_CONSENT_METADATA';
export const UPDATE_GENDER_VALUES = 'UPDATE_GENDER_VALUES';
export const UPDATE_PROVINCE_VALUES = 'UPDATE_PROVINCE_VALUES';

export const SAAS_ADD_QUESTION = 'SAAS_ADD_QUESTION';
export const SAAS_ADD_MULTIPLE_QUESTION = 'SAAS_ADD_MULTIPLE_QUESTION';
export const SAAS_ADD_SINGLE_QUESTION = 'SAAS_ADD_SINGLE_QUESTION';
export const SET_APPROVAL_LAST_UPDATED_SCREEN =
  'SET_APPROVAL_LAST_UPDATED_SCREEN';
export const GET_SAAS_INCENTIVE_METADATA = 'GET_SAAS_INCENTIVE_METADATA';
export const FETCHING_DASHBOARD_WEBSITE_TYPES =
  'FETCHING_DASHBOARD_WEBSITE_TYPES';
export const GET_DASHBOARD_WEBSITE_TYPE_LIST =
  'GET_DASHBOARD_WEBSITE_TYPE_LIST';
// release notes
export const GET_RELEASE_NOTE = 'GET_RELEASE_NOTE';

// Email Briefing - brand details
export const EMAIL_ASYNC_IN_PROGRESS = 'EMAIL_ASYNC_IN_PROGRESS';
export const EMAIL_TYPE_ASYNC_IN_PROGRESS = 'EMAIL_TYPE_ASYNC_IN_PROGRESS';
export const EMAIL_CAMPAIGN_DETAILS_ASYNC_IN_PROGRESS =
  'EMAIL_TYPE_ASYNC_IN_PROGRESS';
export const UPDATE_EMAIL_BRAND_DETAILS = 'UPDATE_BRAND_DETAILS';
export const UPDATE_EMAIL_TYPE = 'UPDATE_EMAIL_TYPE';
export const SET_EMAIL_BRAND_DETAILS = 'SET_EMAIL_BRAND_DETAILS';
export const EMAIL_CROSS_BRAND = 'EMAIL_CROSS_BRAND';
export const FORCE_EMAIL_Type = 'FORCE_EMAIL_Type';
export const RESET_EMAIL_BRAND_DETAILS_PAGE = 'RESET_EMAIL_BRAND_DETAILS_PAGE';
export const SET_EMAIL_EMAIL_TYPE = 'SET_EMAIL_EMAIL_TYPE';
export const RESET_EMAIL_TYPE_PAGE = 'RESET_EMAIL_TYPE_PAGE';
export const SET_EMAIL_CAMPAIGN_DETAILS = 'SET_EMAIL_CAMPAIGN_DETAILS';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

// email briefings list
export const FETCH_EMAIL_BRIEFINGS = 'FETCH_EMAIL_BRIEFINGS';
export const FETCH_EMAIL_BRIEFINGS_SUCCESS = 'FETCH_EMAIL_BRIEFINGS_SUCCESS';
export const FETCH_EMAIL_BRIEFINGS_FAILURE = 'FETCH_EMAIL_BRIEFINGS_FAILURE';
export const UPDATE_TEMPLATE_OBJECT = 'UPDATE_TEMPLATE_OBJECT';

// brand defaults
export const FETCH_BRAND_DEFAULTS = 'FETCH_BRAND_DEFAULTS';
export const FETCH_BRAND_DEFAULTS_SUCCESS = 'FETCH_BRAND_DEFAULTS_SUCCESS';
export const FETCH_BRAND_DEFAULTS_FAILURE = 'FETCH_BRAND_DEFAULTS_FAILURE';
export const SAVE_BRAND_DEFAULTS = 'SAVE_BRAND_DEFAULTS';
export const SAVE_BRAND_DEFAULTS_SUCCESS = 'SAVE_BRAND_DEFAULTS_SUCCESS';
export const SAVE_BRAND_DEFAULTS_FAILURE = 'SAVE_BRAND_DEFAULTS_FAILURE';

// brand defaults history logs
export const FETCH_BRAND_DEFAULTS_HISTORY_LOGS =
  'FETCH_BRAND_DEFAULTS_HISTORY_LOGS';
export const FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS =
  'FETCH_BRAND_DEFAULTS_HISTORY_LOGS_SUCCESS';
export const FETCH_BRAND_DEFAULTS_HISTORY_LOGS_FAILURE =
  'FETCH_BRAND_DEFAULTS_HISTORY_LOGS_FAILURE';

// email briefings list
export const DELETE_EMAIL_BRIEFING = 'DELETE_EMAIL_BRIEFING';
export const DELETE_EMAIL_BRIEFING_SUCCESS = 'DELETE_EMAIL_BRIEFING_SUCCESS';
export const DELETE_EMAIL_BRIEFING_FAILURE = 'DELETE_EMAIL_BRIEFING_FAILURE';

// email core templates
export const FETCH_EMAIL_CORE_TEMPLATES = 'FETCH_EMAIL_CORE_TEMPLATES';
export const FETCH_EMAIL_CORE_TEMPLATES_SUCCESS =
  'FETCH_EMAIL_CORE_TEMPLATES_SUCCESS';
export const FETCH_EMAIL_CORE_TEMPLATES_FAILURE =
  'FETCH_EMAIL_CORE_TEMPLATES_FAILURE';

export const DELETE_EMAIL_CORE_TEMPLATE = 'DELETE_EMAIL_CORE_TEMPLATE';
export const DELETE_EMAIL_CORE_TEMPLATE_SUCCESS =
  'DELETE_EMAIL_CORE_TEMPLATE_SUCCESS';
export const DELETE_EMAIL_CORE_TEMPLATE_FAILURE =
  'DELETE_EMAIL_CORE_TEMPLATE_FAILURE';

// email live templates
export const FETCH_LIVE_EMAIL_TEMPLATES = 'FETCH_LIVE_EMAIL_TEMPLATES';
export const FETCH_LIVE_EMAIL_TEMPLATES_SUCCESS =
  'FETCH_LIVE_EMAIL_TEMPLATES_SUCCESS';
export const FETCH_LIVE_EMAIL_TEMPLATES_FAILURE =
  'FETCH_LIVE_EMAIL_TEMPLATES_FAILURE';

// feedback form
export const OPEN_FEEDBACK_FORM = 'OPEN_FEEDBACK_FORM';
export const CLOSE_FEEDBACK_FORM = 'CLOSE_FEEDBACK_FORM';
export const TOGGLE_FEEDBACK_FORM_SUBMISSION =
  'TOGGLE_FEEDBACK_FORM_SUBMISSION';
export const FETCH_FEEDBACKS = 'FETCH_FEEDBACKS';
export const SET_FEEDBACKS = 'SET_FEEDBACKS';

// config
export const GET_CAMPAIGN_CONFIG = 'GET_CAMPAIGN_CONFIG';
export const GET_ROUTINE_CONFIG = 'GET_ROUTINE_CONFIG';
//dashboard matrix
export const GET_DASHBOARD_MATRIX = 'GET_DASHBOARD_MATRIX';
export const GET_DASHBOARD_MATRIX_DATA = 'GET_DASHBOARD_MATRIX_DATA';

// Email Briefing - Design Email
export const EMAIL_DESIGN_ASYNC_INPROGRESS = 'EMAIL_DESIGN_ASYNC_INPROGRESS';
export const SET_EMAIL_BREIFINGS = 'SET_EMAIL_BREIFINGS';
export const UPDATE_EMAIL_BREIFINGS_STORE = 'UPDATE_EMAIL_BREIFINGS_STORE';
export const RESET_EMAIL_BREIFINGS = 'RESET_EMAIL_BREIFINGS';

// Email Briefing Save template
export const EMAIL_OPEN_SAVE_TEMPLATE = 'EMAIL_OPEN_SAVE_TEMPLATE';
export const EMAIL_CLOSE_SAVE_TEMPLATE = 'EMAIL_CLOSE_SAVE_TEMPLATE';

// Notification
export const SET_NOTIFICATION_MESSAGE = 'SET_NOTIFICATION_MESSAGE';

// for row block
export const FETCH_EMAIL_RAW_BLOCKS = 'FETCH_EMAIL_RAW_BLOCKS';
export const FETCH_EMAIL_RAW_BLOCKS_SUCCESS = 'FETCH_EMAIL_RAW_BLOCKS_SUCCESS';
export const FETCH_EMAIL_RAW_BLOCKS_FAILURE = 'FETCH_EMAIL_RAW_BLOCKS_FAILURE';
export const USER_APPROVER_EMAIL_VALIDATION = 'USER_APPROVER_EMAIL_VALIDATION';
export const GET_EMAIL_CAMPAIGN = 'GET_EMAIL_CAMPAIGN';
export const ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION =
  'ASYNC_EMAIL_IN_PROGRESS_AUTHORIZATION';
export const DELETE_EMAIL_RAW_BLOCK_SUCCESS = 'DELETE_EMAIL_RAW_BLOCK_SUCCESS';
export const FETCHING_BUSINESS_GROUP = 'FETCHING_BUSINESS_GROUP';
export const GET_BUSINESS_GROUP_LIST = 'GET_BUSINESS_GROUP_LIST';
export const UPDATE_BUSINESS_GROUP_DETAILS = 'UPDATE_BUSINESS_GROUP_DETAILS';

// email briefings list
export const FETCH_EMAIL_BRIEFINGS_COUNT = 'FETCH_EMAIL_BRIEFINGS_COUNT';
export const SET_EMAIL_BRIEFINGS_COUNT = 'SET_EMAIL_BRIEFINGS_COUNT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const ASYNC_SMS_IN_PROGRESS_AUTHORIZATION =
  'ASYNC_SMS_IN_PROGRESS_AUTHORIZATION';

// bannerActionTypes.js
export const OPEN_CW_BANNER_MODAL = 'OPEN_CW_BANNER_MODAL';
export const CLOSE_CW_BANNER_MODAL = 'CLOSE_CW_BANNER_MODAL';
export const FETCH_BANNER_REQUEST = 'FETCH_BANNER_REQUEST';
export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_FAILURE = 'FETCH_BANNER_FAILURE';
export const SAVE_BANNER_REQUEST = 'SAVE_BANNER_REQUEST';
export const SAVE_BANNER_SUCCESS = 'SAVE_BANNER_SUCCESS';
export const SAVE_BANNER_FAILURE = 'SAVE_BANNER_FAILURE';
export const SET_ADMIN_CONFIG = 'SET_ADMIN_CONFIG';
// email briefings
// WA briefings
export const ASYNC_WA_COMMS_IN_PROGRESS = 'ASYNC_WA_COMMS_IN_PROGRESS';
export const SET_WA_COMMS = 'SET_WA_COMMS';
export const UNSET_WA_COMMS = 'UNSET_WA_COMMS';
export const GET_WA_CAMPAIGN_DETAILS = 'GET_WA_CAMPAIGN_DETAILS';

// wa core templates
export const FETCH_WA_CORE_TEMPLATES = 'FETCH_WA_CORE_TEMPLATES';
export const FETCH_WA_CORE_TEMPLATES_SUCCESS =
  'FETCH_WA_CORE_TEMPLATES_SUCCESS';
export const FETCH_WA_CORE_TEMPLATES_FAILURE =
  'FETCH_WA_CORE_TEMPLATES_FAILURE';

export const DELETE_WA_CORE_TEMPLATE = 'DELETE_WA_CORE_TEMPLATE';
export const DELETE_WA_CORE_TEMPLATE_SUCCESS =
  'DELETE_WA_CORE_TEMPLATE_SUCCESS';
export const DELETE_WA_CORE_TEMPLATE_FAILURE =
  'DELETE_WA_CORE_TEMPLATE_FAILURE';
export const SET_WA_CORE_TEMPLATES_LOADER = 'SET_WA_CORE_TEMPLATES_LOADER';
// email briefings
export const FETCH_CONSENT_REQUEST = 'FETCH_CONSENT_REQUEST';
export const FETCH_CONSENT_SUCCESS = 'FETCH_CONSENT_SUCCESS';
export const FETCH_CONSENT_FAILURE = 'FETCH_CONSENT_FAILURE';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
