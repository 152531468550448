import React from 'react';
import Modal from 'react-modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Icon } from 'semantic-ui-react';

import { TabPanel } from 'pages/ReportingDashboard/ReportingDashboard';
import { FormBuilder } from 'components/organisms/FormBuilder';
import generateVideoPathSchema from './GenerateVideoPathSchema';
import constants from 'constants/config';
import classNames from 'classnames';
import { LinkButton } from 'components/atoms/LinkButton';

const GenerateVideoPathModal = ({
  isOpen,
  onClose,
  type,
  copyToClipboard,
  isCopyUrl,
  currentVideo,
  handleSubmit,
  isWABriefing
}) => {
  Modal.setAppElement('#root');

  const [activeTab, setActiveTab] = React.useState(0);

  const tabStyle = { fontFamily: 'Open Sans', textTransform: 'none' };

  const handleChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--filePicker-info"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="email-overlay-wrapper image-guideline">
        <div className="cw-heading--saascampaign send-test-email__title mb-20">
          Generate video path
          <span
            className="qna-guideline-close email-overlay-close-modal"
            onClick={onClose}
          ></span>
        </div>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          disableRipple
          style={{ borderBottom: '1px solid #aaa', marginBottom: 20 }}
        >
          <Tab disableRipple style={tabStyle} label="Enter video details" />
          <Tab disableRipple style={tabStyle} label="Video guidelines" />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <FormBuilder
            schema={generateVideoPathSchema(
              handleSubmit,
              onClose,
              currentVideo
            )}
            isInModal
            customButtonsComp={CustomButtons}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <div className="d-flex mb-20 ml-10">
            <div className="scrollable email-overlay-scroll">
              <p>
                Campaign Wizard accepts videos uploaded on TAB i.e. Unilever's
                asset bank only.
              </p>
              <div className="ml-20 mr-20">
                <ul className="mt-20">
                  <li>
                    <strong>Step 1</strong> :&nbsp;
                    <a
                      href="https://theassetbank.unilever.com/en-us/create?tab3094231=UPLOAD+%26+EDIT+METADATA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Upload your video <Icon name="external" />
                    </a>
                    &nbsp;to TAB or find an existing video. Ensure it is marked
                    public with attribute 'Share to website' as 'Yes'
                  </li>
                  <li>
                    <strong>Step 2</strong> : Copy the asset ID of the public
                    video from TAB (E.g. 1282798)
                  </li>
                  {isWABriefing ? (
                    <li>
                      <strong>Step 3</strong> : While creating your WhatsApp
                      message in Campaign Wizard, click on 'Specify video'
                      button to enter TAB ID and video extension ( .mp4, .3gp,
                      etc.)
                    </li>
                  ) : (
                    <li></li>
                  )}

                  <li>
                    <strong>Step 4</strong> : Click on generate URL for Campaign
                    Wizard to auto-populate the video URL in the expected
                    format. The URL would be in the given format&nbsp;
                    <b>
                      https://assets.unileversolutions.com/v1/TAB_ID.extension
                    </b>
                  </li>
                </ul>
              </div>
              <p className="mt-10">Examples</p>
              <div className="ml-20 mr-20">
                <ul className="mt-10">
                  <li>
                    Default:&nbsp;
                    <b>https://assets.unileversolutions.com/v1/1282798.mp4</b>
                  </li>
                </ul>
              </div>
              <p className="mt-10 mb-10">
                To use any other asset transformation parameters, refer to
                this&nbsp;
                <a
                  target={'_blank'}
                  href="https://ce-platform.atlassian.net/wiki/spaces/CPDF/pages/798065380/TAB+Integration+Asset+Pipeline"
                  rel="noreferrer"
                >
                  documentation <Icon name="external" />
                </a>
              </p>
              <p className="mb-20">
                To learn more about TAB, visit&nbsp;
                <a
                  target={'_blank'}
                  href="https://theassetbank.unilever.com/en-us/Training?tab3094175=User+Guides"
                  rel="noreferrer"
                >
                  The Asset Bank <Icon name="external" />
                </a>
              </p>
            </div>
          </div>
        </TabPanel>
      </div>
    </Modal>
  );
};

export const CustomButtons = ({
  isSubmitting,
  onPrevious,
  previousBtnLabel,
  submitBtnLabel,
  onSecondaryClick,
  onSubmitSecondary,
  secondaryBtnLabel,
  isEdit
}) => {
  return isEdit ? (
    <div className="mt-30 mb-10">
      <div>
        <button
          className={classNames('btn btn btn-outline-secondary mr-3 ')}
          type="button"
          onClick={onPrevious}
          disabled={isSubmitting}
        >
          {previousBtnLabel || 'Cancel'}
        </button>
        {onSecondaryClick && (
          <button
            className="btn btn-primary mr-3"
            type="button"
            onClick={() => {
              onSubmitSecondary();
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Loading....' : secondaryBtnLabel || 'Secondary'}
          </button>
        )}
      </div>
      <div className="mt-20">
        <LinkButton
          className="cw-formdraft-link"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
        </LinkButton>
      </div>
    </div>
  ) : (
    <div className="mt-30 mb-10">
      <button
        className={classNames('btn btn btn-outline-secondary mr-3')}
        type="button"
        onClick={onPrevious}
        disabled={isSubmitting}
      >
        {previousBtnLabel || 'Cancel'}
      </button>
      <button
        className="btn btn-primary"
        type="button"
        disabled={isSubmitting}
        onClick={() => {
          console.log('------------type>>>', onSubmitSecondary);
          onSubmitSecondary();
        }}
      >
        {isSubmitting ? 'Loading....' : submitBtnLabel || 'Submit'}
      </button>
    </div>
  );
};

export default GenerateVideoPathModal;
