import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

const CampaignCalendar = ({
  calendarDate,
  list,
  onMonthChange,
  campaignClickHandler
}) => {
  let formattedCampaigns = [];
  try {
    const statusColor = {
      1: '#3788d8',
      2: '#5cb85c',
      3: '#f0ad4e',
      4: '#7705bc',
      5: '#df1642',
      6: '#00d7c4',
      7: '#5cb85c',
      8: '#ffc000'
    };
    const statusmap = {
      1: 'draft',
      2: 'completed',
      3: 'awaitingforapproval',
      4: 'awaitingsetup',
      5: 'rejected',
      6: 'scheduled',
      7: 'live',
      8: 'awaitinginfo'
    };
    // Format campaigns for FullCalendar
    formattedCampaigns = list.map(campaign => ({
      id: campaign.uid,
      title: campaign.summary.campaignName,
      start:
        campaign?.deliverySchedule?.emailTrigger?.details
          ?.triggerConditionStartDate, // Ensure this is ISO format YYYY-MM-DD
      color: statusColor[campaign?.status], // Optional color
      extendedProps: {
        campaign: campaign,
        color: statusColor[campaign?.status],
        statusName: statusmap[campaign?.status]
      }
    }));
  } catch (error) {
    console.error('Error formatting campaigns:', error);
  }
  const formattedDate = moment(calendarDate, 'DD/MM/YY').toDate();

  function renderEventContent(eventInfo) {
    const statusName = eventInfo?.event?.extendedProps?.statusName;
    return (
      <ul
        className={
          eventInfo?.event?.extendedProps?.statusName
            ? 'custom-event ' + statusName
            : 'custom-event'
        }
      >
        <li style={{ listStyleType: 'disc' }}>{eventInfo.event.title}</li>
      </ul>
    );
  }

  return (
    <div>
      <FullCalendar
        key={formattedDate} // Ensures the calendar stays on the selected month
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={formattedDate} // Retains the selected month
        events={formattedCampaigns}
        eventContent={renderEventContent} // Custom rendering
        eventClick={info => {
          campaignClickHandler && campaignClickHandler(info);
        }} // Handle event click
        datesSet={arg => {
          //const newDate = new Date(arg.start);
          const newDate = arg.view.currentStart;
          onMonthChange(newDate); // Notify parent about month change
        }}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next'
        }}
        //dayMaxEventRows={true} // Limit the number of visible events
      />
    </div>
  );
};

export default CampaignCalendar;
