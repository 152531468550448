import * as yup from 'yup';

import { WaVideoSelector } from './WaVideoSelector';
import constants from '../../../constants';
import { buttonBaseClasses } from '@mui/material';
import { getWAFileSize } from 'services/waBriefings';

const videoPathSchema = (handleSubmit, handleCloseForm, defaultValues) => {
  return {
    formType: 'custombuttons',
    submitBtnLabel: 'Generate URL',
    previousBtnLabel: 'Cancel',
    onSecondaryClick: async (data, frm) => {
      console.log(
        '--------------------------------------event -----',
        data,
        frm
      );
      const {
        assetID,
        fileType,
        resizeWidth,
        resizeHeight
      } = data.videoSelector;

      const isTransform = resizeWidth;

      let url = `${constants.TAB_BASE_URL}${assetID}.${fileType.value}`;
      url = isTransform ? url + '?im=' : url;

      if (resizeWidth) {
        url += `Resize=(${resizeWidth},${
          resizeHeight ? resizeHeight : resizeWidth
        })`;
      }

      try {
        const fileSize = await getWAFileSize(url);

        if (parseFloat(fileSize) > 16) {
          frm.setFormErrorMessage('Video file should not be more then 16Mb');
        } else {
          frm.setFormErrorMessage('');
          // frm.evt.preventDefault();
          // frm.evt.stopPropagation();
          return handleSubmit(url, frm, data);
        }
      } catch (error) {
        frm.setFormErrorMessage('Error in fetching file size');
      }
    },
    fields: [
      {
        type: 'custom',
        name: 'videoSelector',
        id: 'videoSelector',
        submitTypeButton: true,
        component: WaVideoSelector,
        defaultValue: {
          ...defaultValues,
          extn: defaultValues?.extn
            ? {
                value: defaultValues.extn,
                label: constants.IMAGE_TYPES.find(
                  type => type.value === defaultValues.extn
                )?.label
              }
            : null
        },
        rules: yup.object({
          assetID: yup
            .string()
            .required('Asset ID is required')
            .matches(/^[0-9]*$/g, 'Please enter valid Asset ID'),
          fileType: yup
            .object({
              label: yup.string(),
              value: yup.string()
            })
            .required('File type is required')
            .default(undefined),
          resizeWidth: yup
            .number('Width must be a number')
            .typeError('Width must be a number')
            .nullable()
            .notRequired()
            .when('resizeHeight', {
              is: data => !!data,
              then: yup
                .number('Width must be a number')
                .required('Width is mandatory when height is specified')
                .typeError('Width is mandatory when height is specified')
            })
            .transform((_, val) => (val !== '' ? Number(val) : null)),
          resizeHeight: yup
            .number('Height must be a number')
            .typeError('Height must be a number')
            .nullable()
            .notRequired()
            .transform((_, val) => (val !== '' ? Number(val) : null))
        })
      }
    ],
    onSubmit: async (data, frm) => {
      const {
        assetID,
        fileType,
        resizeWidth,
        resizeHeight
      } = data.videoSelector;
      console.log('--------------------------------------event -----', frm.evt);
      const isTransform = resizeWidth;

      let url = `${constants.TAB_BASE_URL}${assetID}.${fileType.value}`;
      url = isTransform ? url + '?im=' : url;

      if (resizeWidth) {
        url += `Resize=(${resizeWidth},${
          resizeHeight ? resizeHeight : resizeWidth
        })`;
      }

      try {
        const fileSize = await getWAFileSize(url);

        if (parseFloat(fileSize) > 16) {
          frm.setFormErrorMessage('Video file should not be more then 16Mb');
        } else {
          frm.setFormErrorMessage('');
          frm.evt.preventDefault();
          frm.evt.stopPropagation();
          return handleSubmit(url, frm, data);
        }
      } catch (error) {
        frm.setFormErrorMessage('Error in fetching file size');
      }
    },
    onPrevious: () => {
      handleCloseForm();
    }
  };
};

export default videoPathSchema;
