import {
  GET_BRAND_DETAILS,
  GET_BRAND_COUNTRIES,
  BRAND_COUNTRY_LOADED_SUCCESS,
  BRAND_COUNTRY_LOADED,
  ASYNC_IN_PROGRESS,
  PROFILE_STORE_FOUND,
  RESET_PROFILE_STORE,
  UPDATE_BRAND_DETAILS,
  GET_CAMPAIGN_DETAILS_BRANDDETAILS,
  BRAND_DETAIL_SUCCESS,
  GET_COUNTRY_BRAND_LIST,
  SET_PROFILE_STORE_TYPE,
  SET_PROFILE_STORE_ERROR,
  PROFILE_STORE_IN_PROGRESS,
  RESET_PROFILE_STORE_IN_PROGRESS,
  AUTOPOPULATE_WEBSITETYPE,
  RESET_BRAND_DETAILS
} from '../actionTypes';
import constant from '../constants';

import { epsilonMarket } from '../selectors/index';

const initialState = {
  brands: [],
  countries: [],
  languages: [],
  websiteTypes: [],
  websiteExtensions: [],
  epsilonTypeSites: [],
  asyncInProgress: false,
  isModalOpen: false,
  profileStoreType: null,
  profileStoreExist: null,
  brand: null,
  country: null,
  language: null,
  websiteType: null,
  websiteExtension: null,
  existingCampaign: null,
  profileStoreError: null,
  profileStoreInProgress: false,
  countriesLoaded: false,
  countryDataLoaded: false,
  countrylanguage: null,
  isLoading: false,
  countryId: null,
  isCamapaignEdited: false,
  autopopulateWebsiteType: null,
  websiteCount: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BRAND_DETAILS: {
      //let brands = { brands: action.brandDetails.brands };
      //let countries = { countries: action.brandDetails.countries };
      let languages = { languages: action.brandDetails.languages };
      let websiteTypes = { websiteTypes: action.brandDetails.websiteTypes };
      let websiteExtensions = {
        websiteExtensions: action.brandDetails.websiteExtensions
      };
      let websites = action.brandDetails && action.brandDetails.websiteTypes;
      if (websiteTypes && websiteTypes.websiteTypes) {
        const excludeWeb = ['Adobe Classic', 'Adobe Templated'];
        let updatedWebTypes = websiteTypes.websiteTypes.filter(
          web => web && web.name && !excludeWeb.includes(web.name)
        );
        websiteTypes = { websiteTypes: updatedWebTypes };
      }
      let epsilonTypeSites = {
        epsilonTypeSites: websites.filter(
          website => website.name === 'Adobe SaaS'
        )
      };
      return {
        ...state,
        ...languages,
        ...websiteTypes,
        ...websiteExtensions,
        ...epsilonTypeSites
      };
    }
    case GET_BRAND_COUNTRIES: {
      let countries = {
        // currently filtering out Epsilon market for soft launch as part of WTG-9613
        countries: constant.featureFlags.ENABLE_EPSILON_JOURNEY
          ? action.countryDetailsSorted
          : action.countryDetailsSorted.filter(
              elem => !epsilonMarket(elem.code)
            ),
        brand: null,
        country: null,
        language: null,
        websiteType: null,
        websiteExtension: null,
        countrylanguage: null
      };

      return {
        ...state,
        ...countries
      };
    }
    case GET_COUNTRY_BRAND_LIST: {
      let brands = { brands: action.brands };
      let language = { language: action.countrylanguage };
      let countrylanguage = { countrylanguage: action.countrylanguage };
      let brand = { brand: null };
      return {
        ...state,
        ...brands,
        ...language,
        ...countrylanguage,
        ...brand
      };
    }
    case ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case PROFILE_STORE_FOUND: {
      let profileStoreExist = action.profileStoreExist;
      return { ...state, ...profileStoreExist };
    }
    case RESET_PROFILE_STORE: {
      let profileStoreExist = { profileStoreExist: action.reset };
      return { ...state, ...profileStoreExist };
    }
    case UPDATE_BRAND_DETAILS: {
      let brandDetails = action.brandDetails;
      return { ...state, ...brandDetails };
    }
    case SET_PROFILE_STORE_TYPE: {
      let profileStoretype = {
        profileStoreType: action.profileStore[0].profileStoreType
      };
      return { ...state, ...profileStoretype };
    }
    case SET_PROFILE_STORE_ERROR: {
      let profileStoreError = { profileStoreError: action.error };
      return { ...state, ...profileStoreError };
    }
    case GET_CAMPAIGN_DETAILS_BRANDDETAILS: {
      let {
        brand,
        country,
        language,
        websiteType,
        websiteExtension,
        isEdited
      } = action.campaign;

      let campaignBrandDetails = {
        brand: { value: brand?.code + '-' + brand?._id, label: brand?.name },
        country: {
          value: country?.code + '-' + country?._id,
          label: country?.name
        },
        language: {
          value: language?.code + '-' + language?._id,
          label: language?.name
        },
        websiteType: {
          value: websiteType && websiteType?.code + '-' + websiteType?._id,
          label: websiteType && websiteType?.name
        },
        existingCampaign: true,
        countryId: action.countryId || null,
        isCampaignEdited: isEdited,
        existingLanguage: {
          value: language?.code + '-' + language?._id,
          label: language?.name
        },
        existingWebsiteType: {
          value: websiteType && websiteType?.code + '-' + websiteType?._id,
          label: websiteType && websiteType?.name
        }
      };
      return { ...state, ...campaignBrandDetails };
    }
    case BRAND_DETAIL_SUCCESS: {
      return {
        ...state,
        ...{ brandDetailSuccess: action.payload, profileStoreExist: null }
      };
    }

    case BRAND_COUNTRY_LOADED: {
      return {
        ...state,
        ...{
          countryDataLoaded: action.countryDataLoaded
        }
      };
    }
    case BRAND_COUNTRY_LOADED_SUCCESS: {
      return {
        ...state,
        ...{
          countriesLoaded: action.payload,
          countryDataLoaded: action.countryDataLoaded,
          profileStoreExist: null
        }
      };
    }
    case PROFILE_STORE_IN_PROGRESS: {
      let profileStoreInProgress = { profileStoreInProgress: action.isLoading };
      return { ...state, ...profileStoreInProgress };
    }
    case RESET_PROFILE_STORE_IN_PROGRESS: {
      let profileStoreError = { profileStoreError: null };
      return { ...state, ...profileStoreError };
    }
    case AUTOPOPULATE_WEBSITETYPE: {
      let autopopulateWebsiteType = {
        websiteType: action.autopopulateWebsiteType
      };
      let populatedWebsite = {
        autopopulateWebsiteType: action.autopopulateWebsiteType
      };
      let websiteCount = { websiteCount: action.websiteCount };
      return {
        ...state,
        ...autopopulateWebsiteType,
        ...populatedWebsite,
        ...websiteCount
      };
    }
    case RESET_BRAND_DETAILS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
