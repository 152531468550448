import * as yup from 'yup';
import { useState } from 'react';
import { getBrands } from 'services/emailBriefings';
import { saveBrandDefaults } from 'actions/brandDefaultsActions';
import { ColorPickerInput } from 'components/molecules/ColorPickerInput/ColorPickerInput';
import { fetchBrandDefaults } from 'services/brandDefaultsService';
import { ButtonsDefaultInput } from 'components/molecules/ButtonsDefaultInput/ButtonsDefaultInput';
import { TitleDefaultInput } from 'components/molecules/TitleDefaultInput/TitleDefaultInput';
import { ParagraphDefaultInput } from 'components/molecules/ParagraphDefaultInput/ParagraphDefaultInput';
import { TextLinksDefaultInput } from 'components/molecules/TextLinksDefaultInput/TextLinksDefaultInput';
import BrandHistoryComponent from 'components/organisms/BrandHistoryComponent/BrandHistoryComponent';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import { MultiBrandCheck } from 'pages/NewEmailCampaign/MultiBrandCheck';
import { components } from 'react-select';
import { BrandStyleListInput } from './brandStyleListInput';

const brandStyleFormSchema = (dispatch, { defaultValues, onSave }) => {
  // const [showBrandWithConfig, setShowBrandWithConfig] = useState(false);

  return {
    formType: 'modal',
    submitBtnLabel: 'Save',
    fields: [
      {
        type: 'custom',
        name: 'brand',
        id: 'brand',
        component: BrandStyleListInput,
        label: 'Please select your brand',
        rules: yup
          .object()
          .shape({
            label: yup.string().required(),
            value: yup.string().required(),
            id: yup.string().required()
          })
          .required('Please select brand')
          .default(undefined),
        options: [],
        defaultValue: defaultValues.brand,
        defaultDisabled: true
      },
      {
        type: 'custom',
        name: 'colors',
        id: 'colors',
        label: 'Colour palette',
        description:
          'This colour palette will be readily available within the colour picker of our email editor when you design your brand emails',
        component: ColorPickerInput,
        watchInput: 'brand',
        onWatch: async (brand, onChange) => {
          if (brand) {
            const response = await fetchBrandDefaults(brand.id);
            onChange(response?.colors || []);
          }
        },
        defaultValue: defaultValues.colors
      },
      {
        type: 'custom',
        name: 'title',
        description:
          'When you add a titles to your email body, they will be default displayed in the styling selected below.',
        id: 'title',
        label: 'title',
        component: TitleDefaultInput,
        watchInput: 'brand',
        onWatch: async (brand, onChange) => {
          if (brand) {
            const response = await fetchBrandDefaults(brand.id);
            onChange('color', response?.title?.color || '');
            onChange('fontFamily', response?.title?.fontFamily || null);
          }
        }
      },
      {
        type: 'custom',
        name: 'paragraph',
        description:
          'When you add a paragraph, or any text other than title, to your email body, it will be default displayed in this styling.',
        id: 'paragraph',
        label: 'paragraph',
        component: ParagraphDefaultInput,
        watchInput: 'brand',
        onWatch: async (brand, onChange) => {
          if (brand) {
            const response = await fetchBrandDefaults(brand.id);
            onChange('color', response?.paragraph?.color || '');
            onChange('fontFamily', response?.paragraph?.fontFamily || null);
            onChange('fontSize', response?.paragraph?.fontSize || 14);
          }
        }
      },
      {
        type: 'custom',
        name: 'buttons',
        id: 'buttons',
        label: 'Buttons',
        description:
          'The styling chosen below will apply to all buttons you add to your brand emails.',
        component: ButtonsDefaultInput,
        watchInput: 'brand',
        onWatch: async (brand, onChange) => {
          if (brand) {
            const response = await fetchBrandDefaults(brand.id);
            onChange(
              'backgroundColor',
              response?.buttons?.backgroundColor || ''
            );
            onChange('color', response?.buttons?.color || '');
            onChange('fontSize', response?.buttons?.fontSize || 14);
            onChange('fontFamily', response?.paragraph?.fontFamily || null);
            if (response?.buttons?.fontWeight) {
              onChange('fontWeight', response?.buttons?.fontWeight || null);
            }
            onChange('borderRadius', response?.buttons?.borderRadius || 4);
          }
        },
        defaultValue: defaultValues.colors
      },
      {
        type: 'custom',
        name: 'links',
        description:
          'Your email text links will inherit the colours below but will inherit the font size and typography of the text they are a part of.',
        id: 'links',
        label: 'links',
        component: TextLinksDefaultInput,
        watchInput: 'brand',
        onWatch: async (brand, onChange) => {
          if (brand) {
            const response = await fetchBrandDefaults(brand.id);
            onChange('color', response?.links?.color || '');
          }
        }
      }
    ],
    customView: BrandHistoryComponent,
    onSubmit: (data, { user }) => {
      dispatch(
        saveBrandDefaults(
          {
            ...data,
            brand: data.brand.id,
            userEmail: user.emailId,
            userName: user.name
          },
          onSave
        )
      );
    },
    onPrevious: ({ reset, setValue, getValues }) => {
      const values = getValues();
      const { brand } = values;
      reset();
      setValue('brand', brand);
    },
    disableFormError: true
  };
};

export default brandStyleFormSchema;
