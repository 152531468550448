import Alert from 'components/Alert';
import constants from 'constants/config';
import React from 'react';
import { useWatch } from 'react-hook-form';

const EmailAcsBlockAlert = ({
  watch,
  control,
  onFormValueChange,
  defaultValue,
  campaignConfig
}) => {
  const values = useWatch({
    control,
    name: watch,
    defaultValue
  });
  if (onFormValueChange) {
    onFormValueChange(values, defaultValue);
  }

  console.log('values===>', values);
  console.log('campaignConfig===>', campaignConfig);

  return (
    <>
      {campaignConfig &&
      campaignConfig.ACS_INSTANCE_DECOMMISSIONED_MARKET_LIST &&
      campaignConfig.ACS_INSTANCE_DECOMMISSIONED_MARKET_LIST.includes(
        values && values.value
      ) ? (
        <div className="form-group">
          <Alert
            alertType="warningMessageWithIcon"
            textMessage={constants.SAAS_MESSAGES.acsMarketDecommissionedMsg}
            showComponent={true}
          />
        </div>
      ) : null}
    </>
  );
};

export default EmailAcsBlockAlert;
