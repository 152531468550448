import React, { useState, useCallback } from 'react';
import CampaignCalendar from '../CampaignCalendar';
import TemplatePreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';

function EmailBriefingsListCalendar({
  isCleanTemplates,
  list,
  role,
  handleCalendarMonthChange,
  calendarDate
}) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const handleSubmit = useCallback(clickInfo => {
    setSelectedEvent(clickInfo.event);
    setShowPreview(true); // Show the custom preview
  }, []);

  // Callback function to update state when FullCalendar month changes in CampaignCalendar
  const handleMonthChange = newDate => {
    handleCalendarMonthChange(newDate);
  };
  const campaignClickHandler = clickInfo => {
    setSelectedEvent(clickInfo.event);
    setShowPreview(true); // Show the custom preview
  };
  const handleCloseModel = useCallback(() => {
    setShowPreview(false);
  }, []);

  return (
    <>
      <div className="mt-20">
        <CampaignCalendar
          calendarDate={calendarDate}
          list={list}
          onMonthChange={handleMonthChange}
          campaignClickHandler={handleSubmit}
        />
      </div>

      {/** campaign overlay */}
      <TemplatePreviewOverlay
        isOpen={showPreview}
        onPreviewSelect={() => {
          // onSelectClick(elem);
          // history.push(`/campaign-wizard/emailbriefing/${elem.uid}`);
        }}
        selectedTemplateForPreview={
          selectedEvent?.extendedProps?.campaign?.emailTemplateEditId
        }
        handleCloseForm={handleCloseModel}
        primaryButtonTitle="View"
        previewOf="campaign"
        campaignName={selectedEvent?.title}
        requestersEmail={
          selectedEvent?.extendedProps?.campaign?.requestersEmail
        }
        category={selectedEvent?.extendedProps?.campaign?.emailType}
        brand={selectedEvent?.extendedProps?.campaign?.brand}
        status={selectedEvent?.extendedProps?.campaign?.status}
        // country={selectedEvent?.extendedProps?.campaign?.country?.name}
        campaignUpdatedAt={selectedEvent?.extendedProps?.campaign?.updatedAt}
        isCrossBrand={selectedEvent?.extendedProps?.campaign?.isCrossBrand}
        deliverySchedule={
          selectedEvent?.extendedProps?.campaign?.deliverySchedule
        }
        abTesting={selectedEvent?.extendedProps?.campaign?.abTesting}
        winningVariant={selectedEvent?.extendedProps?.campaign?.abTesting}
      />
    </>
  );
}

export default EmailBriefingsListCalendar;
