import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import constant from 'constants/config';
import '../../custom-css/richTextEditor.css';
import '../../custom-css/waRichTextEditor.css';

const WARichTextInput = forwardRef(
  (
    {
      name,
      id,
      onChange,
      value,
      disabled = false,
      defaultValue,
      height,
      hasError,
      showCharCount,
      maxLength,
      editorRef,
      handleEditorChange
    },
    ref
  ) => {
    //const editorRef = useRef();
    const charCountRef = useRef(null);
    const handleOnChange = richTextData => {
      const defaultCopy = richTextData.getContent();
      const content = richTextData.getContent({ format: 'text' });

      // Calculate character count considering tags as 5 characters
      const adjustedCharCount = content.replace(/{{.*?}}/g, '#####').length;

      if (showCharCount) {
        charCountRef.current.innerText = `${adjustedCharCount}/${maxLength}`;
      }
      const max = parseInt(maxLength);
      if (max && adjustedCharCount <= max) {
        onChange(defaultCopy);
      }
      console.log('defaultCopy: ', defaultCopy);
      handleEditorChange(defaultCopy);
    };

    const handleBeforeAddUndo = (evt, editor) => {
      const length = editor.getContent({ format: 'text' }).length;
      // note that this is the opposite test as in handleUpdate
      // because we are determining when to deny adding an undo level
      if (length > maxLength) {
        evt.preventDefault();
      }
    };

    useEffect(() => {
      if (editorRef.current) {
        if (hasError) {
          const elem = editorRef.current.getContainer();
          elem.classList.add('tinymce-error');
        } else {
          const elem = editorRef.current.getContainer();
          elem.classList.remove('tinymce-error');
        }
      }
    }, [hasError]);

    return (
      <div className="position-relative">
        <Editor
          className="tox tox-statusbar"
          onChange={(evt, editor) => handleOnChange(editor)}
          onEditorChange={(evt, editor) => handleOnChange(editor)}
          onBeforeAddUndo={(evt, editor) =>
            handleBeforeAddUndo ? handleBeforeAddUndo(evt, editor) : null
          }
          value={value}
          disabled={disabled}
          apiKey={constant.TinyMCEapiKey}
          initialValue={defaultValue}
          init={{
            placeholder: 'Type message',
            height: height || 250,
            menubar: false,
            plugins: 'lists emoticons',
            toolbar:
              'bold italic strikethrough | monospace | bullist numlist | emoticons',
            toolbar_location: 'top', // You can also use 'bottom' or 'floating'
            branding: false,
            content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: ${
              false ? '#e9ecef' : 'transparent'
            } }`,
            formats: {
              bold: { inline: 'strong', remove: 'all' },
              italic: { inline: 'em', remove: 'all' },
              strikethrough: { inline: 'del' },
              monospace: {
                inline: 'span',
                styles: { 'font-family': 'monospace' }
              }
            },
            style_formats: [
              { title: 'Custom format', format: 'custom_format' }
            ],
            setup: function(editor) {
              // Add a custom button for monospace text
              editor.ui.registry.addButton('monospace', {
                text: 'Monospace',
                onAction: function() {
                  editor.formatter.toggle('monospace');
                }
              });

              //   editor.ui.registry.addMenuButton('myCustomMenu', {
              //     text: 'Add Variables',
              //     fetch: function(callback) {
              //       const items = [
              //         {
              //           type: 'menuitem',
              //           text: 'First Name',
              //           onAction: function() {
              //             const customCode = `[FName]`;
              //             editor.insertContent(customCode);
              //           }
              //         },
              //         {
              //           type: 'menuitem',
              //           text: 'Last Name',
              //           onAction: function() {
              //             const customCode = `[LName]`;
              //             editor.insertContent(customCode);
              //           }
              //         }
              //       ];
              //       callback(items);
              //     }
              //   });
              editor.on('keydown', function(event) {
                const selection = editor.selection.getRng();
                const content = editor.getContent({ format: 'text' });

                if (!selection) return;

                const cursorPos = selection.startOffset;
                const parentNode = selection.startContainer;
                const parentText = parentNode.textContent || '';

                // Regex to match {{ ... }} tags
                const regex = /\{\{(.*?)\}\}/g;
                let match;

                while ((match = regex.exec(content)) !== null) {
                  const tagStart = match.index;
                  const tagEnd = tagStart + match[0].length;

                  // Cursor is inside the tag
                  if (cursorPos >= tagStart && cursorPos <= tagEnd) {
                    // Allow navigation keys but prevent modification
                    if (
                      event.key !== 'ArrowLeft' &&
                      event.key !== 'ArrowRight' &&
                      event.key !== 'Shift' &&
                      event.key !== 'Control' &&
                      event.key !== 'Alt' &&
                      event.key !== ',' &&
                      event.key !== 'Enter' &&
                      event.key !== 'Meta'
                    ) {
                      // on end of boundary
                      if (cursorPos === tagEnd && event.key === ' ') {
                        return;
                      }
                      // User is modifying content inside or at the boundary of the tag
                      if (
                        parentText.substring(tagStart, tagEnd).includes('{{')
                      ) {
                        // Remove the tag from content
                        const newContent = content.replace(match[0], '');
                        editor.setContent(newContent);
                        event.preventDefault(); // Block modification
                        break;
                      }
                    }
                  }
                }
              });

              // Prevent pasting content that breaks the tag
              editor.on('paste', function(event) {
                let clipboardData = (
                  event.clipboardData || window.clipboardData
                ).getData('text');
                if (/\{\{.*?\}\}/.test(clipboardData)) {
                  event.preventDefault(); // Block modification
                }
              });
            },
            //forced_root_block: '',
            forced_root_block: false, // Disables <p> tags
            //force_br_newlines: true,
            forced_root_block_attrs: {}, // Ensures no attributes are added when forced_root_block is false
            //newline_behavior: 'linebreak',
            //enter_br: true, // Optionally enable <br> behavior on Enter (if applicable)
            // force_p_newlines: false,
            highlight_on_focus: false,
            paste_preprocess: function(plugin, args) {
              const oldContent = plugin.getContent({ format: 'text' });
              const maxChars = maxLength
                ? parseInt(maxLength) - oldContent.length
                : 100; // Set your character limit
              let content = args.content;

              let plainText = content.replace(/<\/?[^>]+(>|$)/g, '');

              // Trim content to the character limit
              if (plainText.length > maxChars) {
                plainText = plainText.substring(0, maxChars);
              }

              // Replace the content being pasted
              args.content = plainText;
            },
            readonly: disabled ? 1 : 0
          }}
          onInit={(evt, editor) => {
            editorRef.current = editor;
            //editor.onChange(editor.getContent()); // Initialize editor content in form state
            ref({
              ...editor,
              focus: () => {
                editor.focus();
                editor
                  .getContainer()
                  .scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            });
            //editor.formatter.apply('custom_format');
            editor.on('SetContent', function(e) {
              // Select all ordered lists and modify them
              let lists = editor.getDoc().querySelectorAll('ol');
              lists.forEach(function(list, mainIndex) {
                //list.setAttribute('data-list', "true");
                // Optionally, manipulate list items
                const liList = list.querySelectorAll('li');
                liList.forEach(function(listItem, index) {
                  // For example, you can add a custom data attribute to each <li>
                  listItem.setAttribute('data-list', `${mainIndex + 1}`);
                });
              });
            });

            const content = editor.getContent({ format: 'text' });
            if (content.length) {
              // Calculate character count considering tags as 5 characters
              const adjustedCharCount = content.replace(/{{.*?}}/g, '#####')
                .length;
              charCountRef.current.innerText = `${adjustedCharCount}/${maxLength}`;
            }
          }}
        />
        {showCharCount && (
          <span
            ref={charCountRef}
            className="position-absolute text-secondary charcountinput__count-wa"
          >
            {`0/${maxLength}`}
          </span>
        )}
      </div>
    );
  }
);

export default WARichTextInput;
