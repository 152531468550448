import React from 'react';
import { get } from 'lodash';

import { FieldError } from 'components/atoms/FieldError';
import { FormInput } from 'components/molecules/FormInput';
import { FormReactSelect } from 'components/molecules/FormReactSelect';
import constants from '../../../constants';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import { OptionsWithCheckbox } from '../../../components/molecules/FormReactSelect/OptionsWithCheckbox';

export const WaDocumentSelector = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  savedRowData,
  ...props
}) => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);

  return (
    <div className="image-select-container">
      <div className="row">
        <div className="col-md-12">
          <label className="font-weight-bold">Select document</label>
        </div>

        <div className="col-md-8">
          <FormInput
            id="assetID"
            type="text"
            name={`${name}.assetID`}
            label="Asset ID from TAB"
            placeholder="Asset ID from TAB"
            register={register}
            errors={errors}
            key="assetID"
            defaultValue={defaultValue.id}
            setValue={setValue}
          />
          <label className="mt-n2 email-type-desc-font">
            Ensure that the document is public in TAB (Share to website
            attribute should be set to “yes“)
          </label>
        </div>
        <div className="col-md-4">
          <div className="form-group" aria-live="polite">
            <label htmlFor={'fileType'}>File type</label>
            <Controller
              name={`${name}.fileType`}
              control={control}
              defaultValue={defaultValue.extn}
              render={({ field }) => (
                <Select
                  className={classNames('react-select-container', {
                    'reactselect-invalid': hasError
                  })}
                  classNamePrefix="react-select"
                  {...field}
                  options={constants.WA_DOCUMENT_TYPES}
                  placeholder={props.placeholder}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  hideSelectedOptions={false}
                  components={{
                    Option: OptionsWithCheckbox
                  }}
                  onChange={val => {
                    field.onChange(val);
                  }}
                />
              )}
            />
            <FieldError
              errors={errors}
              hasNestedError={props.hasNestedError}
              name={`${name}.fileType`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
