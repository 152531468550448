import classNames from 'classnames';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { getBrands, getBrandStyleConfigBrands } from 'services/emailBriefings';
import Select from 'react-select';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import { FieldError } from 'components/atoms/FieldError';

export const BrandStyleListInput = ({
  name,
  rules,
  register,
  label,
  errors,
  setValue,
  control,
  defaultValue,
  ...props
}) => {
  const brandTypeError = get(errors, `${name}`);
  const hasBrandError = !!(errors && brandTypeError);
  const [isBrandConfig, setBrandConfig] = useState(false);
  const [isSelectLoading, setSelectLoading] = useState(false);

  const [brands, setBrands] = useState([]);
  const [configBrands, setConfigBrands] = useState([]);
  const getBrandDetails = () => {
    getBrands().then(response => {
      const brands = response.map(brand => {
        return {
          value: brand.code,
          label: brand.name,
          id: brand._id
        };
      });
      setBrands(brands);
    });
  };

  const getConfigBrandDetails = () => {
    setSelectLoading(true);
    getBrandStyleConfigBrands().then(response => {
      setSelectLoading(false);
      const brands = response.map(brand => {
        return {
          value: brand.code,
          label: brand.name,
          id: brand._id
        };
      });
      setConfigBrands(brands);
    });
  };

  useEffect(() => {
    getBrandDetails();
  }, []);

  useEffect(() => {
    if (isBrandConfig) {
      getConfigBrandDetails();
    }
  }, [isBrandConfig]);

  return (
    <div
      className={classNames(
        'form-group email-secondary-theme',
        props.formGroupWrapperClass
      )}
      aria-live="polite"
    >
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <label htmlFor={props.id}>{label}</label>
          </div>
          <div>
            <CheckBoxInput
              id="isBrandConfig"
              name="isBrandConfig"
              value={isBrandConfig}
              onChange={() => {
                setBrandConfig(prev => !prev);
              }}
              label="Show brands with configured styling"
              labelClass={`text-size-small`}
            />
          </div>
        </div>
        <Controller
          name={`${name}`}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              className={classNames('react-select-container', {
                'reactselect-invalid': hasBrandError
              })}
              classNamePrefix="react-select"
              {...field}
              options={isBrandConfig ? configBrands : brands}
              placeholder="Please select brand"
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              isLoading={isSelectLoading}
            />
          )}
        />
      </div>

      <FieldError errors={errors} name={`${name}`} />
    </div>
  );
};
