import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { sortBy } from 'lodash';
import {
  fetchEmailBriefingList,
  fetchEmailBriefingCount
} from '../../actions/emailbriefing/emailBriefingsList';
import { resetEmailType } from '../../actions/emailbriefing/emailType';
import { resetEmailBrandDetails } from '../../actions/emailbriefing/emailBrandDetails';
import { resetEmailBriefingsDesign } from '../../actions/emailbriefing/emailDesign';
import {
  getBrandCountries,
  getBrands,
  setNotificationMessage
} from '../../actions/dashboard';
import { resetAppProps } from '../../actions/app';
import { flushSelectedTemplate } from 'actions/emailbriefing/selectedTemplate';
import { setCampaignToEdit } from '../../actions/overview';
import { sortArray, getDateRange } from '../../selectors';
import constant from '../../constants';
import { lastUpdatedData } from '../../components/DashboardData';
import EmailBriefingList from '../../components/organisms/EmailBriefingList/EmailBriefingList';
import CountryFilter from 'components/organisms/Filters/CountryFilter/CountryFilter';
import BrandFilter from 'components/organisms/Filters/BrandFilter/BrandFilter';
import EmailTypeFilter from 'components/organisms/Filters/EmailTypeFilter/EmailTypeFilter';
import LastUpdatedFilter from 'components/organisms/Filters/LastUpdatedFilter/LastUpdatedFilter';
import StatusFilter from 'components/organisms/Filters/StatusFilter/StatusFilter';
import AdvancedSearch from 'components/AdvanceSearch';
import AppliedFilters from 'components/organisms/Filters/AppliedFilters/AppliedFilters';
import Slider from 'components/atoms/Slider/Slider';
import { CleanTemplatesSelect } from 'components/atoms/Slider/Slider';
import ViewSwitcher from 'components/molecules/ViewSwitcher';
import CheckBoxInput from 'components/molecules/CheckBoxInput';
import NotificationMessage from 'components/Messages';
import CrossLink from './CrossLink';
import CheckboxDropDown from 'components/CheckboxDropDown';
import classNames from 'classnames';
import { SimpleSelect } from 'components/atoms/SimpleSelect';
import moment, { calendarFormat } from 'moment';

const DASHBOARD_SORT_OPTIONS = [
  {
    label: 'Updated recently',
    value: 'updatedAt-desc'
  },
  {
    label: 'Updated oldest',
    value: 'updatedAt-asc'
  },
  {
    label: 'Earliest delivery',
    value: 'triggerSortDate-asc'
  },
  {
    label: 'Country A-Z',
    value: 'country-asc'
  },
  {
    label: 'Brand A-Z',
    value: 'brand-asc'
  }
];

function convertObjectToArray(obj) {
  const arr = [];
  for (const key in obj) {
    arr.push({ label: obj[key], value: key });
  }
  return arr;
}

function convertObjectToArrayForFilter(obj) {
  const arr = [];
  for (const key in obj) {
    arr.push({ label: constant.emailStatus[obj[key]], value: obj[key] });
  }
  return arr;
}

const defaultStatus = {};

const allStatusExceptDraft = convertObjectToArray(defaultStatus);

function PageWrapper({ isTabView = false, children }) {
  return isTabView ? (
    <>{children}</>
  ) : (
    <div className="col-sm-12">
      <div className="cw-section cw-dashboard ml-20">{children}</div>
    </div>
  );
}

class EmailDashboard extends Component {
  constructor(props) {
    super(props);
    const currentDate = new Date();
    const defaultDates = this.getMonthStartEnd(currentDate);

    console.log(
      '------default start end ',
      currentDate,
      defaultDates.startOfMonth,
      defaultDates.endOfMonth
    );

    this.state = {
      thumbnailView: true,
      isCleanTemplates: false,
      sortClass: 'cw-sort cw-sort--asc',
      sortBy: 'desc',
      sortKey: 'updatedAt',
      lastUpdated: '',
      brandcountry: [],
      brands: [],
      status: allStatusExceptDraft,
      emailType: [],
      isOpen: false,
      value: undefined,
      defaultFiltersEnabled: true,
      isAdvFilterOpened: false,
      showMyCampaigns: false,
      emailWith: '',
      emailPrimaryTheme: '',
      emailSecondaryTheme: '',
      emailWithIncentives: '',
      showFilters: false,
      sortDropdownValue: {
        label: 'Updated recently',
        value: 'lastUpdatedDate-desc'
      },
      calenderView: false,
      calendarDateStart: defaultDates.startOfMonth,
      calendarDateEnd: defaultDates.endOfMonth,
      selectedView: 'grid',
      isFirstLoad: true
    };

    this._getCountries = this._getCountries.bind(this);
    this._getBrands = this._getBrands.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this._clearAllFilters = this._clearAllFilters.bind(this);
    this._handleDropDownValue = this._handleDropDownValue.bind(this);
    this._toggleAdvFilter = this._toggleAdvFilter.bind(this);
    this._toggleFilters = this._toggleFilters.bind(this);
    this.handleCalendarMonthChange = this.handleCalendarMonthChange.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
  }
  componentDidMount() {
    this._setDefaultLastUpdatedFilter();
    this.props.setCampaignToEdit(true);

    this.props.getBrandCountries();
    this.props.getBrands();
    this.props.fetchEmailBriefingCount({ countType: 'dashboardstats' });
    this.sortDashboard = this.sortDashboard.bind(this);
  }

  componentWillUnmount() {
    this.props.resetAppProps(false);
    this.props.setNotificationMessage('', undefined, '');
  }

  _getCountries() {
    let { countries } = this.props;
    if (Object.keys(countries).length > 0) {
      return countries.map(function(country) {
        return { value: country.code + '-' + country._id, label: country.name };
      });
    }
  }

  _getBrands() {
    let { brands } = this.props;
    if (Object.keys(brands).length > 0) {
      const brandsList = brands.map(brand => ({
        value: `${brand.code}-${brand._id}`,
        label: brand.name
      }));

      return [{ value: 'isCrossBrand', label: 'Cross Brand' }, ...brandsList];
    }
  }

  _handleDropDownValue(selectedOption, action) {
    const optionName = action && action.name;
    this.setState({ defaultFiltersEnabled: false });

    if (Array.isArray(selectedOption) && selectedOption.length) {
      const sortedStatus = selectedOption.sort(sortArray('label'));

      this.setState(
        {
          [optionName]: sortedStatus
        },
        () => this.onFilterChange(this.state)
      );
    } else if (
      selectedOption.constructor === Object &&
      Object.keys(selectedOption).length
    ) {
      this.onFilterChange({ ...this.state, [optionName]: selectedOption });
      this.setState({ [optionName]: selectedOption });
    } else if (optionName === 'showMyCampaigns') {
      this.setState({ [optionName]: selectedOption }, () =>
        this.onFilterChange(this.state)
      );
    } else {
      if (optionName !== 'lastUpdated') {
        this.setState({ [optionName]: [] }, () =>
          this.onFilterChange(this.state)
        );
      } else {
        this._setDefaultLastUpdatedFilter();
        this.onFilterChange(this.state);
      }
    }
  }

  getFiltersCount() {
    const filters = [
      'brandcountry',
      'brands',
      'emailType',
      'status',
      'emailWith',
      'emailPrimaryTheme',
      'emailSecondaryTheme',
      'emailWithIncentives'
    ];

    let filterCount = filters.reduce(
      (acc, filterKey) => acc + this.state[filterKey].length,
      0
    );

    if (this.state.lastUpdated !== '') {
      filterCount += 1;
    }

    return filterCount;
  }

  onFilterChange(filters, page) {
    const {
      brandcountry,
      brands,
      emailType,
      status,
      lastUpdated,
      query,
      sortKey,
      sortBy,
      showMyCampaigns,
      emailWith,
      emailPrimaryTheme,
      emailSecondaryTheme,
      emailWithIncentives,
      calenderView,
      calendarDateStart,
      calendarDateEnd,
      selectedView,
      isFirstLoad
    } = filters;
    const queryParams = {
      sort: `${sortKey}:${sortBy}`,
      page: page || 1
    };
    console.log('---------filters-------', filters);

    if (brandcountry) {
      queryParams.country = brandcountry.map(
        country => country.value.split('-')[1]
      );
    }

    if (brands) {
      queryParams.brand = brands.map(brand => {
        const [_, brandName] = brand.value.split('-');
        return brandName ? brandName : brand.value;
      });
    }

    if (emailType) {
      queryParams.emailType = emailType.map(type => type.value);
    }
    if (emailWith) {
      queryParams.emailWith = emailWith.map(e => e.value);
    }
    if (emailPrimaryTheme) {
      queryParams.emailPrimaryTheme = emailPrimaryTheme.map(e => e.value);
    }
    if (emailSecondaryTheme) {
      queryParams.emailSecondaryTheme = emailSecondaryTheme.map(e => e.value);
    }
    if (emailWithIncentives) {
      queryParams.emailWithIncentives = emailWithIncentives.map(e => e.value);
    }
    if (status) {
      queryParams.status = status.map(status => status.value);
    }

    if (lastUpdated && lastUpdated.value !== 'All time') {
      queryParams.updatedAt = lastUpdated.value;
    }

    if (query) {
      queryParams.query = query;
    }

    if (showMyCampaigns) {
      const userData = this.props.authorizedUserData;
      queryParams.showMyCampaigns = `${encodeURIComponent(userData.emailId)}|${
        userData.group
      }`;
    }

    if (selectedView === 'calendar') {
      //queryParams.calenderView=true
      console.log(
        '-------filter cal status-------',
        selectedView,
        lastUpdated,
        calendarDateStart,
        calendarDateEnd
      );
      delete queryParams.updatedAt;
      queryParams.calenderViewSchedule =
        calendarDateStart + ' - ' + calendarDateEnd;
    }

    this.props.fetchEmailBriefingList(queryParams);
  }

  sortDashboard() {
    const { sortBy } = this.state;
    const updates = {};
    if (sortBy === 'desc') {
      updates.sortBy = 'asc';
      updates.sortClass = 'cw-sort cw-sort--desc';
    } else {
      updates.sortBy = 'desc';
      updates.sortClass = 'cw-sort cw-sort--asc';
    }
    this.setState(updates, () => this.onFilterChange(this.state));
  }

  checkCampaignsExist = () => {
    return this.props.list && this.props.list.length ? true : false;
  };

  _setDefaultLastUpdatedFilter = () => {
    //setting default last updated filter to last 30 days
    const getcurentMonthDateRange = getDateRange('last 30 days');
    this.defaultLastUpdatedValue = getcurentMonthDateRange;
    this.setState(
      {
        lastUpdated: {
          value: getcurentMonthDateRange,
          label: 'Last 30 days'
        }
      },
      () => this.onFilterChange(this.state)
    );
  };

  _clearAllFilters(event) {
    event.preventDefault();

    this.setState(
      {
        sortBy: 'desc',
        status: [],
        brands: [],
        brandcountry: [],
        emailType: [],
        lastUpdated: '',
        defaultFiltersEnabled: false,
        emailWith: '',
        emailPrimaryTheme: '',
        emailSecondaryTheme: '',
        emailWithIncentives: ''
      },
      () => this.onFilterChange(this.state)
    );
  }

  removeFilter(value, filterKey) {
    this.setState({ defaultFiltersEnabled: false });
    if (this.state[filterKey]) {
      if (this.state[filterKey].constructor === Array) {
        const typedata = [...this.state[filterKey]];
        typedata.forEach((item, index) => {
          if (item.value === value) {
            typedata.splice(index, 1);
          }
        });
        this.setState(
          {
            [filterKey]: typedata
          },
          () => this.onFilterChange(this.state)
        );
      }
      if (!this.state[filterKey].length) {
        this.setState(
          {
            [filterKey]: ''
          },
          () => this.onFilterChange(this.state)
        );
      }
    }
  }

  onPageChange = pageNumber => {
    this.onFilterChange(
      {
        ...this.state
      },
      pageNumber
    );
  };

  handleSearch = query => {
    this.setState({ query }, () => this.onFilterChange(this.state));
  };

  onChangeSlider = () => {
    this.setState(({ thumbnailView }) => ({ thumbnailView: !thumbnailView }));
  };
  onCalenderView = () => {
    this.setState(({ calenderView }) => {
      return { calenderView: !calenderView };
    });
  };

  toggleCleanTemplates = () => {
    this.setState(({ isCleanTemplates }) => ({
      isCleanTemplates: !isCleanTemplates
    }));
  };

  handleShowMyCampaignsOnChange = () => {
    this._handleDropDownValue(!this.state.showMyCampaigns, {
      name: 'showMyCampaigns'
    });
    //this.setState(({ showMyCampaigns }) => ({ showMyCampaigns: !showMyCampaigns }));
  };

  _toggleAdvFilter() {
    this.setState(s => ({ isAdvFilterOpened: !s.isAdvFilterOpened }));
  }

  // _getEmailWith() {
  //   return ;
  // }

  // _getEmailWithIncentives() {
  //   return ;
  // }

  _toggleFilters() {
    this.setState(state => ({ showFilters: !state.showFilters }));
  }

  handleSort = e => {
    const [key, order] = e.value.split('-');
    this.setState({
      sortDropdownValue: e
    });
    this.setState(
      {
        sortKey: key,
        sortBy: order
      },
      () => this.onFilterChange(this.state)
    );
  };

  getMonthStartEnd = date => {
    const startOfMonth = moment(date)
      .startOf('month')
      .format('DD/MM/YY');
    const endOfMonth = moment(date)
      .endOf('month')
      .format('DD/MM/YY');
    return { startOfMonth, endOfMonth };
  };

  handleCalendarMonthChange = newDate => {
    const start = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const end = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
    const { startOfMonth, endOfMonth } = this.getMonthStartEnd(newDate);

    console.log('----- on change ------', startOfMonth, endOfMonth);

    if (this.state.isFirstLoad) {
      console.log(
        'Initial Load, only filter change called:',
        startOfMonth,
        endOfMonth
      );
      this.setState({ isFirstLoad: false }); // Mark as initialized
      this.onFilterChange(this.state);
    }

    if (
      !this.state.isFirstLoad &&
      this.state.calendarDateStart !== startOfMonth
    ) {
      console.log(
        'User Navigated, update state only:',
        startOfMonth,
        endOfMonth
      );
      this.setState({
        calendarDateStart: startOfMonth,
        calendarDateEnd: endOfMonth
      });
      this.onFilterChange({
        ...this.state,
        calendarDateStart: startOfMonth,
        calendarDateEnd: endOfMonth
      });
    }
  };

  handleViewChange = newView => {
    this.setState({
      calenderView: newView === 'calendar',
      selectedView: newView
    });
    this.onFilterChange({ ...this.state, selectedView: newView });
    console.log(
      '---------selected view---------',
      newView,
      this.state.selectedView
    );
  };

  render() {
    const {
      asyncInProgress,
      showLoader,
      authorizedUserData,
      pageNumber = 1,
      totalCount,
      limit,
      list
    } = this.props;

    const {
      brandcountry,
      brands,
      lastUpdated,
      status,
      emailType,
      sortClass,
      defaultFiltersEnabled,
      thumbnailView,
      calenderView,
      isCleanTemplates,
      showMyCampaigns,
      emailWith,
      emailPrimaryTheme,
      emailSecondaryTheme,
      emailWithIncentives,
      selectedView
    } = this.state;

    const recordShowStart = (pageNumber - 1) * limit + 1;
    const recordShowto = _.min([recordShowStart + limit - 1, totalCount]);

    const {
      userrole,
      resetEmailBrandDetails,
      resetEmailType,
      campaignConfig,
      resetEmailBriefingsDesign,
      flushSelectedTemplate
    } = this.props;
    return (
      <>
        <PageWrapper isTabView={this.props.isTabView}>
          <NotificationMessage
            showcomponent={this.props.notificationMessage}
            message={this.props.notificationMessage}
            type={this.props.notificationType}
            link={this.props.notificationLink}
            campaignname={this.props.notificationCampaign}
            notificationMessageComp={this.props.notificationMessageComp}
          />

          <div className="cw-listing--header mb-30 d-flex align-items-center justify-content-between">
            <h2 className="cw-heading--secondary mr-4 d-inline-block">
              Email campaigns
            </h2>
          </div>
          <div className="d-flex flex-justify-between">
            <AdvancedSearch
              searchEvent={this.handleSearch}
              placeholder="Search using campaign names"
              email
              getLabel={record => record.summary.campaignName}
              campaignConfig={this.props.campaignConfig}
              hideToolTip={true}
              className={this.props.isTabView ? 'mt-20' : ''}
              toggleFilters={this._toggleFilters}
            />
            <NavLink
              className="btn btn-primary btn-newcampaign"
              to={'/campaign-wizard/new-email-campaign'}
              onClick={() => {
                resetEmailBrandDetails();
                resetEmailType();
                resetEmailBriefingsDesign();
                flushSelectedTemplate();
              }}
              style={{ width: '20%' }}
            >
              Create email
            </NavLink>
          </div>

          {/* Role based filter */}
          <div className="row mt-15 cw-dashboard__filters">
            {this.state.showFilters && (
              <div className="d-flex align-items-center col-sm-12">
                {/* Country dropdown */}
                <CountryFilter
                  asyncInProgress={asyncInProgress}
                  value={brandcountry}
                  onChange={this._handleDropDownValue}
                  options={this._getCountries()}
                  classNamePrefix="react-multi-select"
                  isEmailCampaign={true}
                />

                {/* Brand Filter */}
                <BrandFilter
                  asyncInProgress={asyncInProgress}
                  value={brands}
                  onChange={this._handleDropDownValue}
                  options={this._getBrands()}
                  classNamePrefix="react-multi-select"
                  optionsClass={`${
                    this.state.brands.find(b => b.value === 'isCrossBrand')
                      ? 'indent'
                      : ''
                  }`}
                  isEmailCampaign={true}
                />

                <EmailTypeFilter
                  asyncInProgress={asyncInProgress}
                  value={emailType}
                  onChange={this._handleDropDownValue}
                  options={constant.EMAIL_TYPE_OPTIONS}
                  classNamePrefix="react-multi-select"
                  isEmailCampaign={true}
                />

                {/* Status Dropdown */}
                <StatusFilter
                  asyncInProgress={asyncInProgress}
                  value={status}
                  onChange={this._handleDropDownValue}
                  options={convertObjectToArrayForFilter(constant.EMAIL_STATUS)}
                  classNamePrefix="react-multi-select"
                  isEmailCampaign={true}
                />

                {/* Last Updated Dropdown */}
                <LastUpdatedFilter
                  isLoading={asyncInProgress}
                  value={lastUpdated}
                  onChange={this._handleDropDownValue}
                  options={lastUpdatedData}
                  classNamePrefix="react-multi-select"
                  isEmailCampaign={true}
                />
              </div>
            )}
          </div>

          {this.state.showFilters && (
            <div className="cw-dashboard__filters--advance">
              <button
                className="cw-dashboard__filters--advance-toggle advFilter"
                onClick={this._toggleAdvFilter}
              >
                {!this.state.isAdvFilterOpened ? (
                  <span className="dark-text toggle-text">Show more</span>
                ) : (
                  <span className="dark-text toggle-text">Show less</span>
                )}
                <i
                  className={`toggle-arrow fa ${
                    !this.state.isAdvFilterOpened
                      ? 'fa-chevron-down'
                      : 'fa-chevron-up'
                  }`}
                ></i>
              </button>
              <div
                className={!this.state.isAdvFilterOpened ? 'hide' : 'show row'}
              >
                <div className="d-flex align-items-center col col-lg-8">
                  <div className="filter-item mr-10">
                    <div className="form-group">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'react-multi-select-container'}
                        classNamePrefix="react-multi-select"
                        menuIsOpen={true}
                        name={'emailWith'}
                        label={'Emails with A/B tests'}
                        hideSelectedOptions={false}
                        placeholder={'Emails with A/B tests'}
                        value={this.state.emailWith}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        onChange={this._handleDropDownValue}
                        options={constant.EMAIL_ABTEST_FILTER_OPTIONS}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  <div class="filter-item mr-10">
                    <div className="form-group">
                      <CheckboxDropDown
                        isMulti={true}
                        className={'react-multi-select-container'}
                        classNamePrefix="react-multi-select"
                        menuIsOpen={true}
                        name={'emailWithIncentives'}
                        label={'Show emails with'}
                        hideSelectedOptions={false}
                        placeholder={'Email with incentives'}
                        value={this.state.emailWithIncentives}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        onChange={this._handleDropDownValue}
                        options={constant.EMAIL_COUPON_FILTER_OPTIONS}
                        isClearable={false}
                      />
                    </div>
                  </div>
                  <div className="filter-item">
                    <div className="form-group">
                      <CheckboxDropDown
                        isMulti={true}
                        className="react-multi-select-container emailSecondaryTheme"
                        classNamePrefix="react-multi-select"
                        menuIsOpen={true}
                        name={'emailSecondaryTheme'}
                        label={'Secondary content theme'}
                        hideSelectedOptions={false}
                        placeholder={'Secondary content theme'}
                        value={this.state.emailSecondaryTheme}
                        isDisabled={asyncInProgress}
                        isLoading={asyncInProgress}
                        onChange={this._handleDropDownValue}
                        options={constant.EMAIL_SECONDARY_THEMES}
                        isClearable={false}
                        isEmailCampaign={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <AppliedFilters
            removeFilter={this.removeFilter}
            filters={{
              brands,
              brandcountry,
              emailType,
              status,
              lastUpdated,
              showMyCampaigns,
              emailWith,
              emailPrimaryTheme,
              emailSecondaryTheme,
              emailWithIncentives
            }}
            filtersCnt={this.getFiltersCount()}
            _clearAllFilters={this._clearAllFilters}
          />

          <div className="d-flex justify-content-between align-items-center">
            <CrossLink
              isTabView={this.props.isTabView}
              emailCampaignCount={this.props.emailBriefingsCount}
            />
            <div
              className={classNames('dashboard-toggle-wrapper col-sm-8', {
                'justify-content-end': !thumbnailView
              })}
            >
              <SimpleSelect
                options={DASHBOARD_SORT_OPTIONS}
                selected={this.state.sortDropdownValue}
                handleChange={this.handleSort}
              />
              <CheckBoxInput
                id="showMyCampaigns"
                value={showMyCampaigns}
                label="My campaigns"
                onChange={this.handleShowMyCampaignsOnChange}
                className={classNames('email-dashboard-checkbox', {
                  'flex-one': thumbnailView
                })}
                labelClass="email-dashboard-checkbox__label"
              />
              {/* <Slider
                isCleanTemplates={isCleanTemplates} // for dropdown
                toggleCleanTemplates={this.toggleCleanTemplates} // for dropdown
                checked={thumbnailView} // for dropdown and grid
                onChange={this.onChangeSlider} // for grid
                calenderView={this.onCalenderView}
                label="Thumbnail view"
                name="list-view-toggle"
                className={classNames(
                  'email-dashboard-slider justify-content-end',
                  {
                    'flex-one': thumbnailView,
                    'ml-20': !thumbnailView
                  }
                )}
              /> */}
              {this.state.selectedView === 'grid' ? (
                <CleanTemplatesSelect
                  isCleanTemplates={isCleanTemplates}
                  toggleCleanTemplates={this.toggleCleanTemplates}
                />
              ) : null}

              <ViewSwitcher
                selectedView={this.state.selectedView}
                onViewChange={this.handleViewChange}
              />
            </div>
          </div>
          <EmailBriefingList
            thumbnailView={thumbnailView}
            calenderView={calenderView}
            isCleanTemplates={isCleanTemplates}
            showLoader={showLoader}
            authorizedUserData={authorizedUserData}
            recordShowStart={recordShowStart}
            recordShowto={recordShowto}
            pageNumber={pageNumber}
            totalCount={totalCount}
            limit={limit}
            sortDashboard={this.sortDashboard}
            onPageChange={this.onPageChange}
            checkCampaignsExist={this.checkCampaignsExist}
            role={userrole}
            sortClass={sortClass}
            list={list}
            asyncInProgress={asyncInProgress}
            defaultFiltersEnabled={defaultFiltersEnabled}
            handleCalendarMonthChange={this.handleCalendarMonthChange}
            selectedView={selectedView}
            calendarDate={this.state.calendarDateStart}
          />
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = state => ({
  authorizedUserData: state.authorized.authorizedUserData,
  showLoader: state.dashboard.showLoader,
  countries: state.dashboard.countries,
  brands: state.dashboard.brands,
  list: state.emailBriefings.data.data,
  pagination: state.emailBriefings.data.pagination,
  asyncInProgress: state.emailBriefings.isLoading,
  totalCount: state.emailBriefings.data.pagination.total,
  pageNumber: state.emailBriefings.data.pagination.page,
  limit: state.emailBriefings.data.pagination.limit,
  emailBriefingsCount: state.emailBriefings.count,
  notificationMessage: state.dashboard.notificationMessage,
  notificationType: state.dashboard.notificationType,
  campaignConfig: state.app.campaignConfig,
  notificationMessageComp: state.dashboard.notificationMessageComp,
  notificationLink: state.dashboard.notificationLink,
  notificationCampaign: state.dashboard.notificationCampaign
});

export default connect(mapStateToProps, {
  fetchEmailBriefingList,
  getBrandCountries,
  getBrands,
  resetAppProps,
  resetEmailBrandDetails,
  resetEmailType,
  setCampaignToEdit,
  setNotificationMessage,
  resetEmailBriefingsDesign,
  flushSelectedTemplate,
  fetchEmailBriefingCount
})(EmailDashboard);
