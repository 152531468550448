import React, { useState, useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const WAVariablesDropdown = ({
  tagOptions,
  tagLimits,
  onTagInsert,
  fields,
  remove,
  register,
  name,
  defaultValueDynamicVar,
  isTextArea,
  disabled,
  maxTotalSelections,
  control,
  totalSelections,
  setTotalSelections
}) => {
  const [isAddbuttonOptionsOpen, setIsAddbuttonOptionsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to track the menu element
  // const [totalSelections, setTotalSelections] = useState(0);
  // Prepare options with the `isDisabled` property based on selection limits
  const availableTags =
    tagOptions &&
    tagOptions.map(tag => ({
      ...tag,
      isDisabled:
        tagLimits[tag.value] <= 0 || totalSelections >= maxTotalSelections
    }));

  const handleTagSelect = selectedOption => {
    if (
      tagLimits[selectedOption.value] > 0 &&
      totalSelections < maxTotalSelections
    ) {
      onTagInsert(selectedOption);

      // Update total selections count
      setTotalSelections(prev => prev + 1);
    }
  };

  const handleDeleteTextBox = (index, tagValue) => {
    remove(index, tagValue);

    // Decrement total selections count when a tag is removed
    setTotalSelections(prev => Math.max(0, prev - 1));
  };
  console.log('-------totalSelections----', totalSelections);
  return (
    <div
      className={
        isTextArea ? 'variable-dropdown-wrap' : 'variable-dropdown-wrap'
      }
    >
      <Select
        options={availableTags}
        onChange={handleTagSelect}
        placeholder={`Add dynamic fields (Max: ${maxTotalSelections})`}
        isSearchable={false}
        className={'variable-dropdown-select'}
        isDisabled={disabled}
        defaultValue={`Add dynamic fields (Max: ${maxTotalSelections})`}
        getOptionLabel={e => (
          <div style={{ opacity: e.isDisabled ? 0.8 : 1 }}>
            {/* {e.label} {e.isDisabled && '(Limit reached)'} */}
            {e.label} {e.isDisabled}
          </div>
        )}
        tempCounter={`counter_${totalSelections}}`}
      />

      {/* Render text boxes for each selected tag */}
      <div className="dynamic-variables" style={{ marginTop: '20px' }}>
        {fields &&
          fields.map((field, index) => (
            <div
              key={field.id}
              style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
            >
              <input
                type="text"
                value={field.tagValue}
                className="form-control"
                readOnly
              />
              {/* <input type="text" value={field.value} /> */}
              <Controller
                name={`${name + 'DynamicVariables'}.${index}.value`}
                control={control}
                defaultValue={field.value}
                render={({ field }) => {
                  return (
                    <input {...field} type="text" className="form-control" />
                  );
                }}
              />
              {/* <input
                type="text"
                {...(register &&
                  register(`${name + 'DynamicVariables'}.${index}.value`))} // Register for editable input
                defaultValue={field.value}
                className="form-control"
              /> */}
              <button
                type="button"
                className="btn-circle btn-remove"
                onClick={() => {
                  handleDeleteTextBox(index, field.tagValue);
                  console.log(
                    '---- delete clicked ----',
                    index,
                    field.tagValue
                  );
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WAVariablesDropdown;
