import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { usePrevious } from 'hooks/usePrevious';
import waLocationImage from '../../../../images/location-image.png';
import pdfIcon from '../../../../images/pdf-icon.svg';
import docIcon from '../../../../images/doc-icon.svg';
import xlsIcon from '../../../../images/xls-icon.svg';
import pptIcon from '../../../../images/ppt-icon.svg';
import txtIcon from '../../../../images/txt-icon.svg';
import downloadIcon from '../../../../images/download-icon.svg';
import { get } from 'lodash';

const fileIconMap = {
  pdf: pdfIcon,
  doc: docIcon,
  docx: docIcon,
  ppt: pptIcon,
  pptx: pptIcon,
  xls: xlsIcon,
  xlsx: xlsIcon,
  txt: txtIcon
};

const WAChatHeader = ({ data, hasLTOOffer }) => {
  const [hasImageError, setHasImageError] = useState(false);
  const prevImage = usePrevious(data);
  let waHeaderFormatted = '';

  const waReplaceTags = (waBodyTextDynamicVariables, waBodyTextFormated) => {
    waBodyTextDynamicVariables.forEach(variable => {
      const { tagValue, value } = variable;
      waBodyTextFormated = waBodyTextFormated.replace(
        new RegExp(tagValue, 'g'),
        value
      );
    });
    return waBodyTextFormated;
  };

  if (data?.headerTextDynamicVariables && data?.headerText) {
    waHeaderFormatted = waReplaceTags(
      data.headerTextDynamicVariables,
      data.headerText
    );
  }

  useEffect(() => {
    if (data && data.type === 'headerImage' && prevImage) {
      if (data.headerImage !== prevImage.headerImage) {
        if (hasImageError) {
          setHasImageError(false);
        }
      }
    }
  }, [data]);

  if (!data) {
    return null;
  }

  if (data && data.type === 'headerText') {
    return (
      <div className="header-text-container">
        <span className="header-text">
          {waHeaderFormatted || data.headerText}
        </span>
      </div>
    );
  }

  const handleError = () => {
    if (!hasImageError) {
      setHasImageError(true);
    }
  };

  if (data && data.type === 'headerImage') {
    return (
      <div
        className={classNames('header-image-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data.headerImage && !hasImageError ? (
          <img
            src={data.headerImage}
            className={classNames('img-fluid header-img', {
              'rounded-top': true,
              'rounded-bottom': !hasLTOOffer
            })}
            alt="header-image"
            onError={handleError}
          />
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="far fa-image fa-lg"></i>
            </span>
          </div>
        )}
      </div>
    );
  }
  if (data && data.type === 'headerLocation') {
    return (
      <div
        className={classNames('header-image-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data?.headerLocation?.locationLatitude &&
        data?.headerLocation?.locationLongitude ? (
          <img
            src={waLocationImage}
            className={classNames('img-fluid header-img', {
              'rounded-top': true,
              'rounded-bottom': !hasLTOOffer
            })}
            alt="header-image"
            onError={handleError}
          />
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="fas fa-map-marker-alt fa-lg"></i>
            </span>
          </div>
        )}
        {(data?.headerLocation?.locationName ||
          data?.headerLocation?.locationAddress) && (
          <div className="location-bottom-container">
            {data?.headerLocation?.locationName && (
              <p className="header-text">
                {data?.headerLocation?.locationName}
              </p>
            )}
            {data?.headerLocation?.locationAddress && (
              <p className="">{data?.headerLocation?.locationAddress}</p>
            )}
          </div>
        )}
      </div>
    );
  }

  if (data && data.type === 'headerVideo') {
    return (
      <div
        className={classNames('header-video-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        {data.headerVideo && !hasImageError ? (
          <div className="header-video-wrap">
            <video width="216" height="120">
              <source src={data.headerVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="header-video-overlay">
              <div className="header-video-overlay__play-btn">
                <span>
                  <i class="fas fa-play-circle"></i>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="image-placeholder">
            <span className="image-placeholder-icon">
              <i className="far fa-image fa-lg"></i>
            </span>
          </div>
        )}
      </div>
    );
  }

  if (data && data.type === 'headerDocument') {
    const size = get(data, 'documentSelector.query.size');
    const extention = get(data, 'documentSelector.extn');
    const documentURL = get(data, 'headerDocument', null);
    return documentURL ? (
      <div
        className={classNames('header-document-container', {
          'pad-bottom': !hasLTOOffer
        })}
      >
        <div className="header-doc-wrap">
          <div className="doc-icon">
            <img
              className="doc-image"
              src={fileIconMap[extention]}
              alt={'doc file icon'}
            />
          </div>
          <div className="doc-info">
            <span className="first-text">{size ? `${size}MB` : ''}</span>{' '}
            <span className="file-info-text file-extn">{extention}</span>
          </div>
          <div className="doc-down">
            <img
              className="doc-down-img"
              src={downloadIcon}
              alt={'doc file icon'}
            />
          </div>
        </div>
      </div>
    ) : null;
  }
};

export default WAChatHeader;
