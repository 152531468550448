import React, { useRef, useState, useEffect } from 'react';
import WARichTextInput from '../WARichTextInput';
//import { Controller, useWatch } from 'react-hook-form';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';

import { FieldError } from '../../atoms/FieldError';
import { get } from 'lodash';
import WAVariablesDropdown from '../WAVariablesDropdown';
import classNames from 'classnames';
import variableOptionsData from './variableOptionsData';

const WARichTextInputWrapper = ({
  label,
  name,
  id,
  control,
  register,
  setValue,
  watch,
  defaultValue,
  defaultValueDynamicVar,
  disabled,
  formGroupWrapperClass,
  errors,
  showAddVariables,
  maxLength,
  showCharCount
}) => {
  const [totalSelections, setTotalSelections] = useState(0);
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  //------ NEW START------

  const { fields, append, remove } = useFieldArray({
    // move it to WAVariablesDropdown
    control,
    name: `${name + 'DynamicVariables'}`
  });
  const editorContent = useWatch({ control, name: name });

  const editorRef = useRef(null);
  const [tagLimits, setTagLimits] = useState(
    variableOptionsData.reduce(
      (acc, tag) => ({ ...acc, [tag.value]: tag.maxSelections }),
      {}
    )
  );

  useEffect(() => {
    // sets the DB value
    setValue(name + 'DynamicVariables', defaultValueDynamicVar);
  }, [defaultValueDynamicVar]);

  const handleTagInsert = selectedOption => {
    if (editorRef.current) {
      const editor = editorRef.current;
      editor.insertContent(`${selectedOption.value} `);

      // Update selection count for the selected tag
      setTagLimits(prevLimits => ({
        ...prevLimits,
        [selectedOption.value]: prevLimits[selectedOption.value] - 1
      }));

      // Add the selected tag to the textBoxes state
      // setTextBoxes((prevTextBoxes) => [
      //   ...prevTextBoxes,
      //   {
      //     label: selectedOption.label,
      //     value: selectedOption.defaultValue,
      //     tagValue: selectedOption.value
      //   }
      // ]);

      // // Update form value for editor content
      // setValue(name, editor.getContent());

      // Append to textBoxes array managed by useFieldArray
      append({
        label: selectedOption.label,
        value: selectedOption.defaultValue,
        tagValue: selectedOption.value
      });

      // Update form value for editor content
      setValue('editorContent', editor.getContent());
      setTotalSelections(prev => prev - 1);
    }
  };

  const handleEditorChange = content => {
    setValue('editorContent', content);
    // Filter textBoxes to remove tags no longer present in the editor content
    fields.forEach((field, index) => {
      if (!content.includes(field.tagValue)) {
        remove(index);
        setTagLimits(prevLimits => ({
          ...prevLimits,
          [field.tagValue]: (prevLimits[field.tagValue] || 0) + 1
        }));
        setValue(
          'editorContent',
          content.replace(new RegExp(`${field.tagValue}`, 'g'), '') // Remove from mentions content
        );
        setTotalSelections(prev => prev + 1);
      }
    });

    // setTextBoxes((prevTextBoxes) =>
    //   prevTextBoxes.filter((box) => content.includes(box.tagValue))
    // );

    // // Update tag limits for removed tags
    // const usedTags = textBoxes.map((box) => box.tagValue);
    // setTagLimits((prevLimits) => {
    //   const newLimits = { ...prevLimits };
    //   usedTags.forEach((tag) => {
    //     if (!content.includes(tag)) {
    //       newLimits[tag] = (newLimits[tag] || 0) + 1;
    //     }
    //   });
    //   return newLimits;
    // });

    // // Update form value for editor content
    // setValue(name, content);
  };

  const removeTagFromEditor = tagValue => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const content = editor.getContent();

      // Create a new content string without the tag
      const updatedContent = content.replace(tagValue, '');

      // Update the editor content
      editor.setContent(updatedContent);

      // Update the form state to reflect the new content
      setValue('editorContent', updatedContent);
      setTotalSelections(prev => Math.max(0, prev + 1)); // Decrement selection count
    }
  };
  // ----- NEW END------
  return (
    <div className={classNames('form-group', formGroupWrapperClass)}>
      <label htmlFor={id}>{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        shouldUnregister={true}
        render={({ field }) => (
          <WARichTextInput
            {...field}
            defaultValue={defaultValue}
            hasError={hasError}
            editorRef={editorRef}
            handleEditorChange={handleEditorChange}
            showCharCount={showCharCount}
            maxLength={maxLength}
            disabled={disabled}
          />
        )}
      />
      <div className="mt-1">
        <WAVariablesDropdown
          tagOptions={variableOptionsData}
          tagLimits={tagLimits}
          onTagInsert={handleTagInsert}
          fields={fields}
          // remove={remove}
          remove={index => {
            const tagValue = fields[index].tagValue;
            remove(index); // Remove the text box entry from useFieldArray
            removeTagFromEditor(tagValue); // Remove the corresponding tag from editor
          }}
          register={register} // Pass register to TagSelect
          name={name}
          defaultValueDynamicVar={defaultValueDynamicVar}
          disabled={disabled}
          maxTotalSelections={variableOptionsData.length} // Set global maximum selections
          control={control}
          totalSelections={totalSelections}
          setTotalSelections={setTotalSelections}
        />
      </div>
      <FieldError errors={errors} name={name} />
    </div>
  );
};

export default WARichTextInputWrapper;
