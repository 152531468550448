import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../Card';
import { getEmailTemplateThumbail } from 'components/molecules/EmailTemplateThumbnail';
import CardBody, {
  BrandAndCountry,
  CampaignTitle
} from 'components/organisms/CardBody/CardBody';
import constant from '../../../../constants';
import EmailBriefingPreviewOverlay from 'pages/EmailCampaignTemplate/templatePreviewOverlay';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import { shouldRedirectToExecution } from '../../../../selectors';
import moment from 'moment';

const GRADIENTS = [
  'linear-gradient(180deg, rgba(255, 113, 155, 0.41) 34.38%, rgba(253, 223, 166, 0.44) 100%)',
  'linear-gradient(180deg, rgba(255, 113, 181, 0.4) 34.38%, rgba(204, 166, 253, 0.4) 100%)',
  'linear-gradient(180deg, rgba(113, 204, 255, 0.4) 34.38%, rgba(166, 253, 191, 0.4) 100%)',
  'linear-gradient(180deg, rgba(227, 113, 255, 0.42) 34.38%, rgba(166, 191, 253, 0.4) 100%)'
];

const formatDateTime = (dateTime, format) => {
  if (dateTime) {
    const momentParsedDate = moment.utc(dateTime);
    return momentParsedDate.isValid() ? momentParsedDate.format(format) : '';
  }
  return '';
};

function EmailBriefingMasonryCard({
  elem,
  history,
  role,
  index,
  isCleanTemplates
}) {
  const {
    emailTemplateEditId = {},
    summary = {},
    emailType,
    updatedAt,
    status,
    requestersEmail,
    brand,
    country: { name: countryName },
    isCrossBrand,
    deliverySchedule,
    abTesting,
    winningVariant,
    requestorsName
  } = elem;
  const { authorizedUserData } = useSelector(state => state.authorized);

  const { campaignName } = summary;
  const thumbnail = getEmailTemplateThumbail(emailTemplateEditId);
  const gradient = GRADIENTS[index % 4];
  const [isTemplatePreviewOpen, setIsTemplatePreviewOpen] = useState(false);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(
    {}
  );

  const brandName = Array.isArray(brand) ? brand.map(b => b.name) : brand.name;

  const handleCloseTemplatePreview = () => {
    setIsTemplatePreviewOpen(false);
    setSelectedTemplateForPreview({});
  };

  const onSelectClick = ele => {
    history.push(getViewLink(ele));
  };

 
  let deliveryDate = null
  if (get(deliverySchedule, 'emailTrigger.type') === 'fixedDateAndtime') {
    let emailDeliveryDate = deliverySchedule.emailTrigger.details.emailDeliveryDate;
    deliveryDate =  formatDateTime(emailDeliveryDate,'DD MMM YYYY')
  }

  if (get(deliverySchedule, 'emailTrigger.type') === 'dynamicTrigger') {
    const startDate =
      deliverySchedule.emailTrigger.details.triggerConditionStartDate;
    deliveryDate =  formatDateTime(startDate,'DD MMM YYYY')
  }

  const getViewLink = ele => {
    const role = get(authorizedUserData, 'group', 'requestor');

    if (
      constant.EMAIL_APPROVAL_PAGE_ACCESS_ROLES.includes(role) &&
      ele.status === constant.EMAIL_STATUS.AWAITING_FOR_APPROVAL
    ) {
      return `/campaign-wizard/emailbriefing/${ele.uid}/approval`;
    } else if (
      constant.EMAIL_EXECUTION_PAGE_ACCESS_ROLES.includes(role) &&
      shouldRedirectToExecution(ele)
    ) {
      return `/campaign-wizard/emailbriefing/${ele.uid}/execution`;
    } else {
      return `/campaign-wizard/emailbriefing/${ele.uid}`;
    }
  };

  return (
    <div
      key={elem._id}
      className="card text-center email-template__card email-template__card--toggle"
    >
      <Card
        hideOnError
        noShadow
        uid={elem.uid}
        thumbnail={thumbnail}
        alt={'Email preview thumbnail'}
        gradient={gradient}
        title={campaignName || 'Untitled'}
      />
      {isCleanTemplates ? null : (
        <>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <EmailStatus small status={status} />
          </div>
          <div
            className="email-template__card__description"
            style={!thumbnail ? { background: 'transparent' } : {}}
          >
            <CampaignTitle
              title={campaignName || 'Untitled'}
              style={{ paddingTop: 8 }}
            />
            <BrandAndCountry
              brand={brandName}
              country={countryName}
              isCrossBrand={isCrossBrand}
            />
          </div>
        </>
      )}
      <CardBody
        hideOverflow
        primaryButton="View"
        onSelect={() => {
          onSelectClick(elem);
        }}
        onPreview={() => {
          setIsTemplatePreviewOpen(true);
          setSelectedTemplateForPreview(emailTemplateEditId);
        }}
        updatedAt={updatedAt}
        status={status}
        gradient={gradient}
        isThumbnailMissing={!thumbnail}
        title={campaignName || 'Untitled'}
        category={constant.emailType[emailType]}
        brand={brandName}
        country={countryName}
        viewLink={getViewLink(elem)}
        isPrimaryButtonLink={true}
        isCrossBrand={isCrossBrand}
        deliveryDate={deliveryDate}
      />
      <EmailBriefingPreviewOverlay
        isOpen={isTemplatePreviewOpen}
        onPreviewSelect={() => {
          onSelectClick(elem);
          // history.push(`/campaign-wizard/emailbriefing/${elem.uid}`);
        }}
        selectedTemplateForPreview={selectedTemplateForPreview}
        handleCloseForm={handleCloseTemplatePreview}
        primaryButtonTitle="View"
        previewOf="campaign"
        campaignName={campaignName}
        requestersEmail={requestersEmail}
        category={constant.emailType[emailType]}
        brand={brandName}
        status={status}
        country={countryName}
        campaignUpdatedAt={updatedAt}
        isCrossBrand={isCrossBrand}
        deliverySchedule={deliverySchedule}
        abTesting={abTesting}
        winningVariant={winningVariant}
        requestorsName={requestorsName}
      />
    </div>
  );
}

export default withRouter(EmailBriefingMasonryCard);
