import React from 'react';
import { ReactComponent as Grid } from '../../../images/grid.svg';
import { ReactComponent as List } from '../../../images/list.svg';
import { ReactComponent as Calendar } from '../../../images/calendar.svg';
import ReactTooltip from 'react-tooltip';

const ViewSwitcher = ({ selectedView, onViewChange }) => {
  const views = ['calendar', 'list', 'grid'];
  const viewIconComponents = {
    calendar: Calendar,
    list: List,
    grid: Grid
  };

  /**
   * <ReactTooltip
        id={'Calendar-view'}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <div
        data-tip={'Calendar view'}
        data-for="Calendar-view"
        onClick={calenderView}
        className={classNames({ icon: true, selected: checked })}
      >
   * 
   */

  return (
    <div className="view-switcher email-dashboard-slider">
      {views.map(view => {
        const ViewIcon = viewIconComponents[view];
        return (
          <>
            <ReactTooltip
              id={`${view}-view`}
              place="bottom"
              type="info"
              multiline={true}
              className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
            />
            <button
              key={view}
              className={`view-button icon ${
                selectedView === view ? 'selected' : ''
              }`}
              onClick={() => onViewChange(view)}
              data-tip={`${view.charAt(0).toUpperCase() + view.slice(1)} View`}
              data-for={`${view}-view`}
            >
              <ViewIcon />
            </button>
          </>
        );
      })}
    </div>
  );
};

export default ViewSwitcher;
