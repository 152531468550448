import React, { useState, forwardRef, useEffect } from 'react';
import GenerateDocumentPathModal from './GenerateDocumentPathModal';
import { FieldError } from 'components/atoms/FieldError';
const getTransformedDocumentData = info => {
  if (info.documentSelector) {
    return {
      id: info.documentSelector.assetID,
      extn: info.documentSelector.fileType.value,
      query: {
        width: info.documentSelector.resizeWidth,
        height: info.documentSelector.resizeHeight,
        size: info.fileSize
      }
    };
  }
  return undefined;
};

const ValueDisplay = ({ value }) => {
  return value ? (
    <a target={'_blank'} href={value} rel="noreferrer">
      {value}
    </a>
  ) : null;
};

const GenerateDocumentInput = forwardRef(
  (
    {
      onChange,
      value,
      name,
      errors,
      defaultValue,
      setValue,
      fieldPrefix,
      getValues,
      disabled,
      register
    },
    ref
  ) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentData, setDocumentData] = useState(
      defaultValue?.documentSelector
    );
    useEffect(() => {
      register && register(`${fieldPrefix}.documentSelector`);
      setValue(`${fieldPrefix}.documentSelector`, documentData);
    }, []);

    return (
      <div className="pt-2 ml-22 pt-0 cw-form-nested" aria-live="polite">
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={() => setIsModalOpen(true)}
          ref={ref}
          disabled={disabled}
        >
          Specify Document
        </button>
        <GenerateDocumentPathModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleSubmit={(url, frm, data) => {
            onChange(url);
            if (data.documentSelector && fieldPrefix) {
              const documentSelector = getTransformedDocumentData(data);
              setDocumentData(documentSelector);
              setValue(`${fieldPrefix}.documentSelector`, documentSelector, {
                shouldTouch: true
              });
            }

            setIsModalOpen(false);
          }}
          handleCloseForm={() => {
            setIsModalOpen(false);
          }}
          type={'src'}
          currentDocument={documentData}
          isWABriefing={true}
        />

        <p className="pt-30">
          <ValueDisplay value={value} />
        </p>

        <FieldError name={name} errors={errors} />
      </div>
    );
  }
);

export default GenerateDocumentInput;
