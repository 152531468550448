import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as Grid } from '../../../images/grid.svg';
import { ReactComponent as List } from '../../../images/list.svg';
import { ReactComponent as CalendarIcon } from '../../../images/calendar.svg';

import { ReactComponent as DownArrow } from '../../../images/down-arrow.svg';
import ReactTooltip from 'react-tooltip';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

const CleanThumbnails = 'Clean thumbnails';
const ThumbnailsWithInfo = 'Thumbnails with info';
const CalendarView = 'Calendar View';

export const CleanTemplatesSelect = ({
  isCleanTemplates,
  toggleCleanTemplates
}) => {
  const [expanded, setExpanded] = useState(false);
  const [options, setOptions] = useState([
    { label: ThumbnailsWithInfo },
    { label: CleanThumbnails }
  ]);

  useEffect(() => {
    setOptions(
      isCleanTemplates
        ? [{ label: CleanThumbnails }, { label: ThumbnailsWithInfo }]
        : [{ label: ThumbnailsWithInfo }, { label: CleanThumbnails }]
    );
  }, [isCleanTemplates]);

  const ref = useRef();

  useOnClickOutside(ref, () => setExpanded(false));

  const handleSelect = label => {
    setExpanded(!expanded);
    if (
      (isCleanTemplates && label === CleanThumbnails) ||
      (!isCleanTemplates && label === ThumbnailsWithInfo)
    ) {
      return;
    }
    toggleCleanTemplates();
  };
  return (
    <span className="email-custom-select" ref={ref}>
      <div
        className="d-flex align-items-center justify-content-end"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="title">
          {isCleanTemplates ? CleanThumbnails : ThumbnailsWithInfo}
        </div>
        <DownArrow className="down-arrow" />
      </div>
      {expanded ? (
        <div className="options">
          {options.map(option => (
            <Option
              key={option.label}
              option={option}
              handleSelect={handleSelect}
            />
          ))}
        </div>
      ) : null}
    </span>
  );
};

export default function Slider(props) {
  const {
    checked,
    onChange,
    calenderView,
    isCleanTemplates,
    toggleCleanTemplates,
    className
  } = props;
  console.log(
    '-------------------->>> checked::',
    checked,
    ' onChange::',
    onChange
  );
  const [selectedView, setSelectedView] = useState(null);

  const handleToggle = view => {
    setSelectedView(view);
    if (view !== CalendarView) {
      toggleCleanTemplates();
    }
  };

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      {checked ? (
        <CleanTemplatesSelect
          isCleanTemplates={isCleanTemplates}
          toggleCleanTemplates={handleToggle}
        />
      ) : null}
      <ReactTooltip
        id={'Calendar-view'}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <div
        data-tip={'Calendar view'}
        data-for="Calendar-view"
        onClick={calenderView}
        className={classNames({ icon: true, selected: checked })}
      >
        <CalendarIcon />
      </div>
      <div
        onClick={checked ? onChange : null}
        data-tip={'List view'}
        data-for={'List-view'}
        className={classNames({ icon: true, selected: !checked })}
      >
        <List />
      </div>
      <ReactTooltip
        id={'List-view'}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />

      <ReactTooltip
        id={'Thumbnail-view'}
        place="bottom"
        type="info"
        multiline={true}
        className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
      />
      <div
        data-tip={'Thumbnail view'}
        data-for="Thumbnail-view"
        onClick={!checked ? onChange : null}
        className={classNames({ icon: true, selected: checked })}
      >
        <Grid />
      </div>
    </div>
  );
}

const Option = ({ option, handleSelect }) => {
  return (
    <div className="option" onClick={() => handleSelect(option.label)}>
      {option.label}
    </div>
  );
};
