import React, { useRef } from 'react';
import Modal from 'react-modal';

import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import { EmailBrandDisplay } from 'components/atoms';
// import MobileDevicePreview from 'components/organisms/MobileDevicePreview';
import MobileDeviceFrame from 'components/organisms/MobileDeviceFrame';
import { get } from 'lodash';

import {
  getDateInDDmmmYYYY,
  getSMSReviewData,
  getWADeliveryCondition
} from 'selectors';
// import { getMobilePreviewDates } from 'utilities/util';

import constants from 'constants/config';

import CampaignPreview from 'images/campaign-preview.svg';

export const WAPreviewOverlay = ({
  isOpen,
  onCloseModal,
  handleCloseForm,
  campaignName,
  brand,
  country,
  isCrossBrand,
  status,
  requestorsEmail,
  primaryButtonTitle = 'Select',
  selectedWaForPreview: template,
  onPreviewSelect,
  hideSelectBtn,
  campaign,
  requestorsName
}) => {
  const previewRef = useRef();
  Modal.setAppElement('#root');

  const hasTimestamp = item => {
    if (item?.lastUpdatedDate) {
      return true;
    } else if (item?.lastUpdatedDate) {
      return true;
    } else {
      return false;
    }
  };

  const getTimestamp = item => {
    return getDateInDDmmmYYYY(item.lastUpdatedDate);
  };

  const brandName = Array.isArray(template?.brand)
    ? template?.brand?.map(b => b.label)
    : template?.brand?.label;

  //   let mobilePreviewData = getSMSReviewData(
  //     campaign,
  //     getMobilePreviewDates(campaign)
  //   );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className="cw-modal cw-modal--template-preview"
      contentLabel="Save row form"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="template-preview-overlay">
        <div>
          <button
            className={`cw-modal--close close-icon`}
            title="Close"
            onClick={handleCloseForm}
          >
            <i className="fas fa-times"></i>
          </button>
          <h3 className={`cw-heading--primary template-title mt-2 mb-10`}>
            <strong
              className="mb-1"
              style={{
                flex: 1,
                minWidth: 0,
                flexBasis: 0,
                wordBreak: 'break-word',
                lineHeight: 'normal'
              }}
            >
              {campaignName}
            </strong>
            {status ? (
              <div className="ml-2 mb-5">
                <EmailStatus small status={status} />
              </div>
            ) : null}
          </h3>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div
            className={'campaign'}
            style={{ flex: 1, minWidth: 0, flexBasis: 0, marginTop: '5px' }}
          >
            <p className="template-category-text">
              {constants.WA_TYPE_LABELS[campaign.waType] || ''}
            </p>
            {template?.brand && template.country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={brandName}
                  country={template.country?.label}
                  isCrossBrand={template.templateUsage === 'crossBrand'}
                  showAllBrands={true}
                />
              </p>
            )}
            {brand && country && (
              <p className="template-info-text">
                <EmailBrandDisplay
                  brand={brand.name}
                  country={country.name}
                  isCrossBrand={isCrossBrand}
                  showAllBrands={true}
                />
              </p>
            )}
          </div>
          {!hideSelectBtn && (
            <button
              className="btn btn-primary ml-2"
              style={{ width: 100 }}
              onClick={() => {
                onPreviewSelect && onPreviewSelect(template);
              }}
            >
              {primaryButtonTitle}
            </button>
          )}
        </div>

        <p className="template-info-text">
          {getWADeliveryCondition(campaign.deliverySchedule).map(text => (
            <div>{text}</div>
          ))}
        </p>

        <div className="mt-25 mb-15 template-preview-box">
          {get(campaign, 'waDesign') ? (
            <MobileDeviceFrame
              ref={previewRef}
              data={{
                ...get(campaign, 'waDesign', {}),
                brand: get(campaign, 'brand'),
                deliverySchedule: get(campaign, 'deliverySchedule', {})
              }}
            />
          ) : (
            <div className="text-center">
              <img src={CampaignPreview} alt={`${campaignName} preview`} />
              <p className="mt-20 text-center preview-text">
                Preview will be available once designed
              </p>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <div className="text-right">
            {(campaign?.requestorsEmail ||
              requestorsEmail ||
              requestorsName) && (
              <p className="template-meta-info">Requestor: {requestorsName}</p>
            )}
            {hasTimestamp(campaign) && (
              <p className="template-meta-info">
                Updated on: {getTimestamp(campaign)}
              </p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
