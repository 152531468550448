import { combineReducers } from 'redux';
import app from './app';
import dashboard from './dashboard';
import brandDetails from './brandDetails';
import campaignType from './campaignType';
import overview from './overview';
import campaignDetails from './campaignDetails';
import questionsAnswers from './questionsAnswers';
import approval from './approval';
import masterData from './masterData';
import prmDetails from './prmDetails';
import gigyaDetails from './gigya';
import review from './review';
import user from './user-management';
import requester from './user-management/requesterTab';
import authorized from './authorization';
import questionsorting from './questionAnswerSorting';
import historyLogs from './historyLogs';
import brandDefaultsHistoryLogs from './brandDefaultsHistoryLogs';
import campaignAuthorAccess from './campaignAuthorAccess';
import saasFormAuthoring from './saasFormAuthoring';
import profileFieldSorting from './profileFieldsSorting';
import consentAndCompliance from './consentAndCompliance';
import feedbackForm from './feedbackForm';

import communicationBrandDetails from './communications/communicationBrandDetails';
import communicationCampaignDetails from './communications/communicationCampaignDetails';
import communicationReviewDetails from './communications/communicationReviewDetails';
import communicationOverview from './communications/communicationOverview.js';
import communicationDashboard from './communications/communicationDashboard';
import preview from './preview';
import emailBrandDetails from './emailbriefing/emailBrandDetails';
import emailType from './emailbriefing/emailType';
import emailCampaignDetails from './emailbriefing/emailCampaignDetails';
import emailBriefings from './emailbriefing/emailBriefings';
import selectedTemplate from './emailbriefing/selectedTemplate';
import emailCoreTemplates from './emailbriefing/emailCoreTemplates';
import emailDesign from './emailbriefing/emailDesign';
import saveTemplateForm from './emailbriefing/saveTemplate';
import notification from './emailbriefing/notification';
import emailCoreRaws from './emailbriefing/emailCoreRaws';
import brandDefaults from './brandDefaults';
import { adminConfig } from './adminConfig';
import waBriefings from './wabriefings/waBriefings';
import waCoreTemplates from './wabriefings/waCoreTemplates';
// two new reducers added
export default combineReducers({
  app,
  dashboard,
  brandDetails,
  campaignType,
  overview,
  campaignDetails,
  questionsAnswers,
  approval,
  masterData,
  prmDetails,
  gigyaDetails,
  review,
  user,
  requester,
  authorized,
  questionsorting,
  historyLogs,
  campaignAuthorAccess,
  saasFormAuthoring,
  communicationBrandDetails,
  communicationCampaignDetails,
  communicationReviewDetails,
  communicationOverview,
  communicationDashboard,
  preview,
  profileFieldSorting,
  consentAndCompliance,
  feedbackForm,
  notification,
  brandDefaults,
  brandDefaultsHistoryLogs,
  emailBrandDetails,
  emailType,
  emailCampaignDetails,
  emailCoreTemplates,
  emailBriefings,
  selectedTemplate,
  emailDesign,
  saveTemplateForm,
  emailCoreRaws,
  adminConfig,
  waBriefings,
  waCoreTemplates
});
