import React, { useState, forwardRef } from 'react';
import GenerateVideoPathModal from './GenerateVideoPathModal';
import { FieldError } from 'components/atoms/FieldError';
const getTransformedVideoData = info => {
  if (info.videoSelector) {
    return {
      id: info.videoSelector.assetID,
      extn: info.videoSelector.fileType.value,
      query: {
        width: info.videoSelector.resizeWidth,
        height: info.videoSelector.resizeHeight
      }
    };
  }
  return undefined;
};

const GenerateVideoInput = forwardRef(
  (
    {
      onChange,
      value,
      name,
      errors,
      defaultValue,
      setValue,
      fieldPrefix,
      getValues,
      disabled
    },
    ref
  ) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoData, setVideoData] = useState(defaultValue?.videoSelector);

    return (
      <div className="pt-2 ml-22 pt-0 cw-form-nested" aria-live="polite">
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={() => setIsModalOpen(true)}
          ref={ref}
          disabled={disabled}
        >
          Specify Video
        </button>
        <GenerateVideoPathModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          handleSubmit={(url, frm, data) => {
            onChange(url);
            if (data.videoSelector && fieldPrefix) {
              const videoSelector = getTransformedVideoData(data);
              setVideoData(videoSelector);
              setValue(`${fieldPrefix}.videoSelector`, videoSelector);
            }

            setIsModalOpen(false);
          }}
          handleCloseForm={() => {
            setIsModalOpen(false);
          }}
          type={'src'}
          currentVideo={videoData}
          isWABriefing={true}
        />

        <p className="pt-30">
          {value ? (
            <a target={'_blank'} href={value} rel="noreferrer">
              {value}
            </a>
          ) : null}
        </p>

        <FieldError name={name} errors={errors} />
      </div>
    );
  }
);

export default GenerateVideoInput;
