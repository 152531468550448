import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OverflowMenu from 'components/organisms/OverflowMenu/OverflowMenu';
import ImageTemplateBlank from 'images/email-template-blank.png';
import constant from '../../../constants';
import EmailStatus from 'components/atoms/EmailStatus/EmailStatus';
import moment from 'moment';
import emailChartIconImg from '../../../images/emailChartIcon.png';
import { checkIsEmailAdminOrACS } from 'selectors';
import { EmailBrandDisplay } from 'components/atoms';

export default function CardBody({
  title,
  category,
  creditCount,
  primaryButton,
  isBlankTemplate,
  isSelectedTemplate,
  hideOverflow,
  status,
  updatedAt,
  onSelect,
  gradient,
  isThumbnailMissing,
  onPreview,
  onDelete,
  brand,
  country,
  isCrossBrand,
  hideSelectBtn,
  hideNameDescirption,
  hideOverflowSelectBtn,
  viewLink,
  isPrimaryButtonLink,
  isEmailTemplate,
  deliveryDate=''
}) {
  const { authorizedUserData } = useSelector(state => state.authorized);
  const isEmailAdminOrACS = checkIsEmailAdminOrACS(authorizedUserData);
  let overflowMenuOptions = [];
  if (hideOverflowSelectBtn) {
    overflowMenuOptions = [
      {
        label: 'Preview',
        onClick: onPreview,
        icon: 'fas fa-eye'
      }
    ];
  } else {
    overflowMenuOptions = [
      {
        label: 'Select',
        onClick: onSelect,
        icon: 'fas fa-hand-pointer'
      },
      {
        label: 'Preview',
        onClick: onPreview,
        icon: 'fas fa-eye'
      }
    ];
  }

  if (!isEmailTemplate) {
    overflowMenuOptions.push({
      label: 'Delete',
      onClick: onDelete,
      icon: 'fas fa-trash'
    });
  }

  return (
    <div
      className="card-body"
      style={
        isSelectedTemplate
          ? {
              display: 'flex',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.9) 22.92%, rgba(0, 0, 0, 0) 100%)'
            }
          : isThumbnailMissing
          ? {
              background: gradient
            }
          : {}
      }
    >
      {isBlankTemplate || isSelectedTemplate || hideOverflow ? null : (
        <OverflowMenu options={overflowMenuOptions} />
      )}
      {updatedAt ? <div style={{ flex: '.25' }}></div> : null}
      <div className={hideNameDescirption ? 'invisible' : 'card-body-contents'}>
        <div className="header">
          <CampaignTitle
            isSelectedTemplate={isSelectedTemplate}
            title={title}
            isBlankTemplate={isBlankTemplate}
          />
          <p
            className="card-text one-line"
            style={isSelectedTemplate ? { color: 'var(--color-green)' } : {}}
          >
            {isSelectedTemplate
              ? 'Resume your progress'
              : isBlankTemplate
              ? 'Endless possibilities'
              : constant.emailType[category]}
          </p>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            {status ? <EmailStatus small status={status} /> : null}
          </div>
        </div>

        {isBlankTemplate && !isSelectedTemplate && (
          <img
            className="blank-template-image"
            src={ImageTemplateBlank}
            alt="Blank Template"
          />
        )}
        {isThumbnailMissing ? (
          <p className="card-error mt-10">
            Preview will be available once designed
          </p>
        ) : null}
        <div
          className={'email-template__card--button-container'}
          style={isSelectedTemplate ? { marginTop: 102 } : {}}
        >
          {isPrimaryButtonLink ? (
            <Link
              className={hideSelectBtn ? 'invisible' : 'btn btn-primary'}
              style={{ lineHeight: '30px' }}
              to={viewLink}
            >
              {primaryButton}
            </Link>
          ) : (
            <button
              className={hideSelectBtn ? 'invisible' : 'btn btn-primary'}
              style={{ lineHeight: '30px' }}
              onClick={onSelect}
            >
              {primaryButton}
            </button>
          )}
          {isBlankTemplate ||
          isSelectedTemplate ||
          isThumbnailMissing ? null : (
            <button
              className="btn preview-btn"
              style={{ lineHeight: '30px' }}
              onClick={onPreview}
            >
              Preview
            </button>
          )}
        </div>
        {!isBlankTemplate && creditCount ? (
          <div className="email-template__card--counter">
            <img
              src={emailChartIconImg}
              alt={emailChartIconImg}
              style={{ height: 12, width: 12 }}
            />{' '}
            Used {creditCount} {creditCount > 1 ? 'times' : 'time'}
          </div>
        ) : null}
      </div>
      <div
        className="card-error updated-at"
        style={{
          flexDirection: 'column'
        }}
      >
        <div className="mt-auto">
          {brand && country ? (
            <BrandAndCountry
              brand={brand}
              country={country}
              isCrossBrand={isCrossBrand}
            />
          ) : null}
        </div>
        {updatedAt ? (
          <span>{moment(updatedAt).format('DD MMM YYYY')}</span>
        ) : null}
        {deliveryDate ? <p>{`Delivery: ${deliveryDate}`}</p> : null}
      </div>
    </div>
  );
}

export const CampaignTitle = ({
  isSelectedTemplate,
  title,
  isBlankTemplate,
  style = {}
}) => (
  <p
    className="card-title one-line mb-1"
    style={{
      ...(isSelectedTemplate
        ? { ...style, color: 'var(--color-primary)' }
        : style),
      wordBreak: 'break-word',
      lineHeight: 'normal'
    }}
  >
    <ReactTooltip
      id={title}
      place="bottom"
      type="info"
      multiline={false}
      className="cw-tooltip cw-email-tooltip saas_new_master_tooltip font-weight-normal"
    />
    <span
      data-for={title}
      data-tip={!isSelectedTemplate && !isBlankTemplate ? title : ''}
    >
      {isSelectedTemplate ? 'Current selection' : title}
    </span>
  </p>
);

export const BrandAndCountry = ({ brand, country, isCrossBrand }) => (
  <div className="mt-10">
    <p className="card-info bold">
      <EmailBrandDisplay brand={brand} isCrossBrand={isCrossBrand} />
    </p>
    <p className="card-info">{country}</p>
  </div>
);
