import React, { Component } from 'react';
import moment from 'moment';
import './history.scss';

class HistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreLogs: false
    };
  }
  render() {
    const { titleClassName = '' } = this.props;
    const collapsibleText = this.state.showMoreLogs
      ? 'View less history '
      : 'View more history ';
    const collapsibleIcon = this.state.showMoreLogs
      ? 'fa-chevron-up'
      : 'fa-chevron-down';
    return (
      <div
        className={
          this.props.isEmailBrief ? 'email-brief-history' : 'cw-section'
        }
      >
        <div className="mb-20">
          <h3
            className={ this.props.isEmailBrief ? 'email-tabular__heading' : `cw-heading--primary ${titleClassName}`}
          >History{this.props.isEmailBrief? <span className="circle-singleline">{this.props.historyLogs.length}</span> : ' ' + this.props.historyLogs.length}</h3>
        </div>
        {this.props.historyLogs.length > 0 ? (
          <div className="cw-campaign--review-section-content mb-40">
            <div className="cw-striped-row">
              <div className="row">
                <div className="col-sm-3">
                  <div className="history-col-heading">
                    {this.props.isEmailBrief
                      ? 'Date and time (UTC)'
                      : 'Date / time UTC'}
                  </div>
                </div>
                <div
                  className={this.props.isEmailBrief ? 'col-sm-5' : 'col-sm-5'}
                >
                  <div className="history-col-heading">{'Event'}</div>
                </div>
                {/* {this.props.isEmailBrief ? (
                  <div className="col-sm-2">
                    <div className="history-col-heading">{'User'}</div>
                  </div>
                ) : null} */}
                <div className="col-sm-4">
                  <div className="history-col-heading">{'Notes'}</div>
                </div>
              </div>
            </div>
            <div
              className={
                this.props.isEmailBrief
                  ? 'cw-striped-email-brief-row'
                  : 'cw-striped-qna-row'
              }
            >
              <div className="row">
                <div className="col-sm-3">
                  <div className="history-col">
                    {this.props.isEmailBrief
                      ? this.getEmailBriefDateAndTime(
                          this.props.historyLogs[0].eventDate
                        )
                      : this.getDateAndTime(
                          this.props.historyLogs[0].eventDate
                        )}
                  </div>
                </div>
                <div
                  className={
                    this.props.isEmailBrief
                      ? 'col-sm-5  d-flex flex-column'
                      : 'col-sm-5'
                  }
                >
                  <div className="history-col">
                    {this.props.historyLogs[0].event}
                  </div>
                  {this.props.isEmailBrief ? (
                    <div className="history-col">
                      <a
                        className="email-brief-font"
                        href={'mailto:' + this.props.historyLogs[0].userName}
                      >
                        {this.props.historyLogs[0].userName}
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* {this.props.isEmailBrief ? (
                  <div className="col-sm-2">
                    <div className="history-col">
                      {this.props.historyLogs[0].userEmail}
                    </div>
                  </div>
                ) : null} */}
                {this.props.isEmailBrief ? (
                  <div className="col-sm-4">
                    <div
                      className="history-col"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      {this.props.historyLogs[0].event === 'Test email sent' ? (
                        this.props.historyLogs[0].notes ? (
                          <>
                            <p>Test email sent to requestor and</p>
                            <div></div>
                            {this.props.historyLogs[0].notes
                              .split(',')
                              .map(e => {
                                return (
                                  <>
                                    <a
                                      className="email-brief-font"
                                      href={'mailto:' + e}
                                    >
                                      {e}
                                    </a>
                                    <div></div>
                                  </>
                                );
                              })}
                          </>
                        ) : (
                          ''
                        )
                      ) : this.props.historyLogs[0].notes ? (
                        this.props.historyLogs[0].notes
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-4">
                    <div
                      className="history-col"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      {this.props.historyLogs[0]?.event === 'Campaign edited'? `Edited by : ${this.props.historyLogs[0]?.userName}` :  this.props.historyLogs[0].notes
                        ? this.props.historyLogs[0].notes
                        : this.props.historyLogs[0].userName }
                      {this.props.historyLogs[0].userName ? ' ' : ''}
                      {/* {this.props.historyLogs[0].userEmail ? (
                        <a
                          href={'mailto:' + this.props.historyLogs[0].userEmail}
                        >
                          {this.props.historyLogs[0].userEmail}
                        </a>
                      ) : (
                        ''
                      )} */}
                      {this.props.historyLogs[0].comments ? `\n` + this.props.historyLogs[0].comments : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.state.showMoreLogs ? (
              <div className="more-logs">
                {this.props.historyLogs.slice(1).map(item => (
                  <div
                    className={
                      this.props.isEmailBrief
                        ? 'cw-striped-email-brief-row'
                        : 'cw-striped-qna-row'
                    }
                    key={item._id}
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="history-col">
                          {this.props.isEmailBrief
                            ? this.getEmailBriefDateAndTime(item.eventDate)
                            : this.getDateAndTime(item.eventDate)}
                        </div>
                      </div>
                      <div
                        className={
                          this.props.isEmailBrief
                            ? 'col-sm-5 d-flex flex-column'
                            : 'col-sm-5'
                        }
                      >
                        <div className="history-col">{item.event}</div>
                        {this.props.isEmailBrief ? (
                          <div className="history-col">
                            <a
                              className="email-brief-font"
                              href={'mailto:' + item.userEmail}
                            >
                              {item.userName}
                            </a>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* {this.props.isEmailBrief ? (
                        <div className="col-sm-2">
                          <div className="history-col">{item.userEmail}</div>
                        </div>
                      ) : null} */}
                      {this.props.isEmailBrief ? (
                        <div className="col-sm-4">
                          <div
                            className="history-col"
                            style={{ lineBreak: 'anywhere' }}
                          >
                            {item.event === 'Test email sent' ? (
                              item.notes ? (
                                <>
                                  <p>Test email sent to requestor and</p>
                                  <div></div>
                                  {item.notes.split(',').map(e => {
                                    return (
                                      <>
                                        <a
                                          className="email-brief-font"
                                          href={'mailto:' + e}
                                        >
                                          {e}
                                        </a>
                                        <div></div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                ''
                              )
                            ) : item.notes ? (
                              item.notes
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-4">
                          <div
                            className="history-col"
                            style={{ lineBreak: 'anywhere' }}
                          >
                            {item?.event === 'Campaign edited'? `Edited by : ${item?.userName}`:
                             item.notes ? item.notes : item.userName}
                            {item.userName ? ' ' : ''}
                            {/* {item.userEmail ? (
                              <a href={'mailto:' + item.userEmail}>
                                {item.userEmail}
                              </a>
                            ) : (
                              ''
                            )} */}
                            {item.comments ? `\n` + item.comments : ''}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {this.props.historyLogs && this.props.historyLogs.length >= 2 ? (
              <a
                className={
                  this.props.isEmailBrief
                    ? 'collapse-h pt-2 email-brief-view-more-font'
                    : 'collapse-h pt-2'
                }
                href="#historyLogsCollapsed"
                role="button"
                aria-expanded="false"
                aria-controls="historyLogsCollapsed"
                onClick={this.handleCollapse}
              >
                <span>{collapsibleText}</span>
                <i className={`fas ml-1 ${collapsibleIcon}`}></i>
              </a>
            ) : null}
          </div>
        ) : (
          <div>{'There are no logs to be displayed.'}</div>
        )}
      </div>
    );
  }

  handleCollapse = event => {
    event.preventDefault();
    this.setState({ showMoreLogs: !this.state.showMoreLogs });
  };

  getDateAndTime = dateObject => {
    const dateWithTime = new Date(dateObject);
    return (
      <>
        <p>
          {moment(dateWithTime)
            .utc()
            .format('DD/MM/YYYY')}
        </p>
        <p>
          {moment(dateWithTime)
            .utc()
            .format('HH:mm')}
        </p>
      </>
    );
  };

  getEmailBriefDateAndTime = dateObject => {
    const dateWithTime = new Date(dateObject);
    return (
      <>
        <p>
          {moment(dateWithTime)
            .utc()
            .format('DD MMM YYYY')}
        </p>
        <p>
          {moment(dateWithTime)
            .utc()
            .format('HH:mm')}
        </p>
      </>
    );
  };
}

export default HistoryComponent;
