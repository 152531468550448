import React from 'react';
import classNames from 'classnames';

const Loader = ({ style = {} }) => {
  return (
    <div className="cw-loader" style={style}>
      <div className="d-flex flex-column justify-content-center ">
        <div className="spinner-border cw-color--primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export const LoaderWithText = ({ style = {}, text }) => {
  return (
    <div className="cw-loader" style={style}>
      <div
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center',
          {
            'loading-bg': !!text
          }
        )}
      >
        <div className="spinner-border cw-color--primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div className="loading-text-wrapper">
          <div className="loader-text">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
