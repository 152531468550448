import React from 'react';
import Loader from '../../Loader';
import Pagination from '../../Pagination';
import EmailBriefingListRow from '../EmailBriefingListRow/EmailBriefingListRow';
import EmailBriefingsListCalendar from '../EmailBriefingsListCalendar/EmailBriefingsListCalendar';
import EmailBriefingsListMasonry from '../EmailBriefingsListMasonry/EmailBriefingsListMasonry';
import EmailBriefingListHeader from './components/EmailBriefingListHeader';

class EmailBriefingList extends React.Component {
  renderDashboard() {
    let {
      asyncInProgress,
      list,
      role,
      authorizedUserData,
      defaultFiltersEnabled
    } = this.props;
    if (asyncInProgress) {
      return (
        <tr>
          <td>
            <Loader />
          </td>
        </tr>
      );
    } else {
      if (!this.props.checkCampaignsExist()) {
        return (
          <tr>
            <td>
              <span>{'0 campaigns match your search'}</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      } else {
        return list.map((campaign, index) => {
          return (
            <EmailBriefingListRow
              key={campaign.uid}
              campaign={campaign}
              index={index}
              role={role}
              defaultFiltersEnabled={defaultFiltersEnabled}
              authorizedUserData={authorizedUserData}
            />
          );
        });
      }
    }
  }
  render() {
    const {
      showLoader,
      sortDashboard,
      recordShowStart,
      recordShowto,
      pageNumber,
      totalCount,
      limit,
      onPageChange,
      sortClass,
      thumbnailView,
      calenderView,
      isCleanTemplates,
      list,
      role,
      asyncInProgress,
      selectedView,
      calendarDate
    } = this.props;
    console.log('--------selected view to toggle--------->>', selectedView);
    return (
      <div className="cw-listing--content">
        {showLoader || asyncInProgress ? (
          <Loader />
        ) : selectedView === 'grid' ? (
          <EmailBriefingsListMasonry
            isCleanTemplates={isCleanTemplates}
            list={list}
            role={role}
          />
        ) : selectedView === 'calendar' ? (
          <>
            <EmailBriefingsListCalendar
              isCleanTemplates={isCleanTemplates}
              list={list}
              role={role}
              handleCalendarMonthChange={this.props.handleCalendarMonthChange}
              calendarDate={calendarDate}
            />
          </>
        ) : (
          <table className="table table-striped-dashboard mb-40 mt-10">
            <caption>List of campaigns</caption>
            <EmailBriefingListHeader
              sortDashboard={sortDashboard}
              sortClass={sortClass}
            />
            <tbody>{this.renderDashboard()}</tbody>
          </table>
        )}
        {showLoader ||
        asyncInProgress ||
        !list.length ||
        selectedView === 'calendar' ? (
          <span>&nbsp;</span>
        ) : (
          <Pagination
            recordShowStart={recordShowStart}
            recordShowto={recordShowto}
            pageNumber={Number(pageNumber)}
            totalRecords={totalCount}
            limit={limit}
            onPageChange={onPageChange}
          />
        )}
      </div>
    );
  }
}

export default EmailBriefingList;
